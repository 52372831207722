import { updateAuth } from '../actions/userProfileAction';
import { batchActions } from 'redux-batched-actions';

export const Auth = store => next => action => {
  const token = window.localStorage.getItem('objUser');

  const actionNext = [action];
  if (!!token) {
    actionNext.push(updateAuth({ userAuth: true }));
  } else {
    actionNext.push(updateAuth({ userAuth: false }));
  }
  return next(batchActions(actionNext));
};
