const defaultConfirmDlg = {
  open: false,
  title: null,
  message: null,
  submitAction: null,
  cancelAction: null,
  primary: true,
  txtBtnSubmit: undefined,
  txtBtnCancel: undefined,
  openConfirm: false,
  attrValueModal: undefined,
  attrValueSpan: undefined,
  attrValueBtn: undefined,
};

export const alert = (
  state = {
    confirmDlg: defaultConfirmDlg,
  },
  action
) => {
  switch (action.type) {
    case "ALERT_ERROR":
      return {
        ...state,
        msg: action.state,
        type: "error",
      };
    case "ALERT_SUCCESS":
      return {
        ...state,
        msg: action.state,
        type: "success",
      };
    case "ALERT_WARNING":
      return {
        ...state,
        msg: action.state,
        type: "warning",
      };
    case "ALERT_INFO":
      return {
        ...state,
        msg: action.state,
        type: "info",
      };
    case "ALERT_CANCEL_ALL":
      return {
        confirmDlg: defaultConfirmDlg,
      };
    case "ALERT_COMFIRM": {
      return {
        ...state,
        confirmDlg: {
          open: true,
          title: action.state.title,
          message: action.state.message,
          submitAction: action.state.submitAction,
          cancelAction: action.state.cancelAction,
          primary: action.state.primary,
          txtBtnSubmit: action.state.txtBtnSubmit,
          txtBtnCancel: action.state.txtBtnCancel,
          attrValueModal: action.state.attrValueModal,
          attrValueSpan: action.state.attrValueSpan,
          attrValueBtn: action.state.attrValueBtn,
        },
      };
    }
    case "CLOSE_CONFIRM": {
      return {
        ...state,
        confirmDlg: defaultConfirmDlg,
      };
    }
    case "ALERT_PANEL_CONFIRM": {
      return {
        ...state,
        openConfirm: action.status,
      };
    }
    default:
      return state;
  }
};

export const alertNotificationSys = (state = {}, action) => {
  switch (action.type) {
    case "ALERT_INFO":
      return {
        options: {
          title: action.options.title,
          message: action.options.message,
          level: "info",
          position: "tr",
        },
      };
    case "ALERT_WARNING":
      return {
        options: {
          title: action.options.title,
          message: action.options.message,
          level: "warning",
          position: "tr",
        },
      };
    case "ALERT_ERROR":
      return {
        options: {
          title: action.options.title,
          message: action.options.message,
          level: "error",
          position: "tr",
        },
      };
    case "ALERT_SUCCESS":
      return {
        options: {
          title: action.options.title,
          message: action.options.message,
          level: "success",
          position: "tr",
        },
      };
    case "ALERT_BUTTON":
      return {
        options: {
          title: action.options.title,
          message: action.options.message,
          level: action.options.type,
          position: "tr",
          autoDismiss: 100,
        },
      };

    case "UPDATE_NOTIFSYS":
      return {
        options: action.options,
      };
    default:
      return state;
  }
};
