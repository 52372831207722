export const alertError = (title = "Error", message = "") => ({
  type: "ALERT_ERROR",
  options: { title, message },
});
export const alertSuccess = (title = "Success", message = "") => ({
  type: "ALERT_SUCCESS",
  options: { title, message },
});
export const alertWarning = (title = "Warning", message = "") => ({
  type: "ALERT_WARNING",
  options: { title, message },
});
export const alertInfo = (title = "Info", message = "") => ({
  type: "ALERT_INFO",
  options: { title, message },
});

export const alertButton = (type, title = "Button", message = "") => ({
  type: "ALERT_BUTTON",
  options: { type, title, message },
});

export const cancelAll = () => ({
  type: "ALERT_CANCEL_ALL",
});

export const alertConfirm = (
  submitAction = () => {},
  cancelAction = () => {},
  title = "Are you sure?",
  message = "",
  primary = true,
  txtBtnSubmit,
  txtBtnCancel,
  attrValueModal,
  attrValueSpan,
  attrValueBtn
) => ({
  type: "ALERT_COMFIRM",
  state: {
    title,
    message,
    submitAction,
    cancelAction,
    primary,
    txtBtnSubmit,
    txtBtnCancel,
    attrValueModal,
    attrValueSpan,
    attrValueBtn,
  },
});

export const alertPanelConfirm = (status) => ({
  type: "ALERT_PANEL_CONFIRM",
  status,
});

export const closeConfirm = () => ({
  type: "CLOSE_CONFIRM",
});

export const alertNotificationSys = (options) => ({
  type: "UPDATE_NOTIFSYS",
  options,
});
