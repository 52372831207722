export const updateInitData = data => ({
  type: 'UPDATE_INIT_DATA',
  state: data,
});

export const updateTopbar = data => ({
  type: 'UPDATE_TOP_BAR',
  state: data,
});

export const updateBadgeNews = data => ({
  type: 'UPDATE_BADGE_NEWS',
  state: data,
});
