export const searchLocation = (
  state = { locationList: [], addressList: [], favLocation: [] },
  action
) => {
  switch (action.type) {
    case "UPDATE_LOCATION_LIST":
      return action.state;
    default:
      return state;
  }
};
export const recentAddress = (state = [], action) => {
  switch (action.type) {
    case "RECENT_ADDRESS_SEARCH_LOCATION":
      return action.state;
    default:
      return state;
  }
};

export const viewConfirm = (state = false, action) => {
  switch (action.type) {
    case "VIEW_CONFIRM_SEARCH_LOCATION": {
      return action.state;
    }
    default:
      return state;
  }
};

export const setZoom = (state = false, action) => {
  switch (action.type) {
    case "SET_ZOOM_SEARCH_LOCATION": {
      return action.state;
    }
    default:
      return state;
  }
};

export const currentLocation = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_CURRENT_LOCATION": {
      return action.state;
    }
    default:
      return state;
  }
};

export const enterLocation = (state = {}, action) => {
  switch (action.type) {
    case "ENTER_LOCATION": {
      return action.state;
    }
    default:
      return state;
  }
};
