import Axios from 'axios';

const axios = Axios.create({
  baseURL: __CHAT_API_URL__,
});

export const RoomApi = {
  roomData: roomId => axios.get(`/room/roomData/${roomId}`),
  listRoom: (username, role) => axios.get(`/room/list/${username}/${role}`),
  listNeedAdminSupportRoom: () => axios.get(`/room/list/needAdminSupport`),
  searchRoom: ({ filter = {}, projection }) =>
    axios.post(`/room/search`, { filter, projection }),
};
