export const TabMenuClick = (id) => {
  if (id === 1) {
    return {
      type: "EXPRESS_DELIVERY",
    };
  } else if (id === 2) {
    return {
      type: "FULL_DAY",
    };
  } else if (id === 3) {
    return {
      type: "NEXT_DAY",
    };
  }
};

// export const FullDayMenu = () => ({
//   type: "FULL_DAY",
// });
// export const NextDayMenu = () => ({
//   type: "NEXT_DAY",
// });
