import Loadable from "react-loadable";
import locale from "./locale/locale.json";
const langSupport = locale.support.reduce((p, c, i, arr) => {
  if (i === 0) {
    return p;
  }
  return p.concat("|").concat(c);
});
const langPattern = `(${langSupport})`;
// 0 = public
// 1 = private sign in

/** head property is <head> tag. Example
 *  head: {
      title: 'Home',
      meta: [
        { name: 'description', content: 'xxx' },
        { property: 'og:type', content: 'article' },
      ],
    },
*/
const defaultMeta = [
  {
    name: "description",
    content:
      "ส่งพัสดุ กระจายสินค้า ส่งอาหาร ส่งเอกสารด้วยแมสเซ็นเจอร์ ขนย้าย ขนส่งด้วยรถกระบะ รับถึงที่ ส่งถึงมือ ทั่วกทม. และปริมณฑล",
  },
  {
    name: "keywords",
    content:
      "SKOOTAR, สกู๊ตตาร์, แมสเซ็นเจอร์, แมสเซนเจอร์, ส่งเอกสาร, ส่งพัสดุ, เดินเอกสาร, ส่งของ, ส่งสินค้า, เก็บเช็ค, วางบิล, ด่วน, กรุงเทพ, กทม, ส่งอาหาร, ปริมณฑล, รายเที่ยว, รายครั้ง, มอเตอร์ไซค์, บริการ, รับ, แอป, แอปพลิเคชัน, แอพพลิเคชั่น, ไทย, ดิลิเวอรี, Messenger, Logistics, document, parcel, collect cheque, deliver invoice, package, App, Application, on demand, Motorbike, Bangkok, BKK, Delivery, Service, Thailand, reliable, safe, เมสเซ็นเจอร์, มืออาชีพ, สมัครงาน, ไว้ใจได้",
  },
];

export default [
  // {
  //   site: "/",
  //   path: `/:lang${langPattern}?`,
  //   page: Loadable({
  //     loader: () => import("./pages/home"),
  //     loading: () => null,
  //   }),
  //   permit: 0,
  //   routeKey: "home",
  //   head: {
  //     title: "SKOOTAR ขนส่งด่วนออนไลน์มือโปร",
  //     meta: defaultMeta,
  //   },
  // },
  {
    site: "/error",
    path: "/error",
    page: Loadable({
      loader: () => import("./pages/error"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "error",
    head: {
      title: "Error",
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/about",
    path: `/:lang${langPattern}?/about`,
    page: Loadable({
      loader: () => import("./pages/about"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "about",
    head: {
      title: "About",
      meta: defaultMeta,
    },
  },
  {
    site: "/neworder",
    path: `/:lang${langPattern}?/neworder`,
    page: Loadable({
      loader: () => import("./pages/neworder"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "neworder",
    head: {
      title: "SKOOTAR ขนส่งด่วนออนไลน์มือโปร",
      meta: [
        {
          name: "description",
          content:
            "เรียกแมสเซ็นเจอร์ด่วน ส่งเอกสาร เก็บเช็ค วางบิล ส่งพัสดุ ส่งอาหาร กระจายสินค้า ลดต้นทุน สั่งงานได้ 24 ชม. ให้บริการด้วยคนขับมือโปร รับงานภายใน 2 นาที",
        },
        {
          name: "keywords",
          content:
            "SKOOTAR, สกู๊ตตาร์, แมสเซ็นเจอร์รายเดือน, แมสเซนเจอร์รายเดือน, ส่งเอกสารรายเดือน, ส่งพัสดุรายเดือน, เดินเอกสารรายเดือน, ส่งของรายเดือน, ส่งสินค้ารายเดือน, เก็บเช็ครายเดือน, วางบิลรายเดือน, ด่วน, กรุงเทพ, กทม, ส่งอาหารรายเดือน, ปริมณฑล, รายเที่ยว, รายครั้ง, รายเดือน, มอเตอร์ไซค์, บริการ, รับ, แอป, แอปพลิเคชัน, แอพพลิเคชั่น, ไทย, ดิลิเวอรี, Messenger, Logistics, document, parcel, collect cheque, deliver invoice, package, App, Application, on demand, Motorbike, Bangkok, BKK, Delivery, Service, Thailand, reliable, safe, เมสเซ็นเจอร์, มืออาชีพ, สมัครงาน, ไว้ใจได้",
        },
      ],
    },
  },
  {
    site: "/freecredit",
    path: `/:lang${langPattern}?/freecredit`,
    page: Loadable({
      loader: () => import("./pages/freecredit"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "freecredit",
    head: {
      title: "Free credit",
      meta: defaultMeta,
    },
  },
  {
    site: "/signupRefer",
    path: `/:lang${langPattern}?/signupRefer/:code?`,
    page: Loadable({
      loader: () => import("./pages/freecredit/signuprefer"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "signuprefer",
    head: {
      title: "Refer friends",
      meta: defaultMeta,
    },
  },
  {
    site: "/dashboard",
    path: `/:lang${langPattern}?/dashboard`,
    page: Loadable({
      loader: () => import("./pages/dashboard"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "dashboard",
    head: {
      title: "Dashboard",
      meta: defaultMeta,
    },
  },
  {
    site: "/t",
    path: `/:lang${langPattern}?/t`,
    page: Loadable({
      loader: () => import("./pages/tracking"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "t",
    head: {
      meta: defaultMeta,
    },
  },
  {
    site: "/c",
    path: `/:lang${langPattern}?/c`,
    page: Loadable({
      loader: () => import("./pages/new-tracking"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "c",
    head: {
      meta: defaultMeta,
    },
  },
  {
    site: "/cn",
    path: `/:lang${langPattern}?/cn`,
    page: Loadable({
      loader: () => import("./pages/trackingCn"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "cn",
    head: {
      meta: defaultMeta,
    },
  },
  {
    site: "/priceklook",
    path: `/:lang${langPattern}?/priceklook`,
    page: Loadable({
      loader: () => import("./pages/priceklook"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "priceklook",
    head: {
      title: "Price Klook",
      meta: defaultMeta,
    },
  },
  {
    site: "/rating",
    path: `/:lang${langPattern}?/rating`,
    page: Loadable({
      loader: () => import("./pages/rating"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "rating",
  },
  {
    site: "/job/report",
    path: `/:lang${langPattern}?/job/report/:jobId?`,
    page: Loadable({
      loader: () => import("./pages/report"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "jobReport",
    head: {
      title: "Report",
      meta: defaultMeta,
    },
  },
  {
    site: "/business",
    path: `/:lang${langPattern}?/business`,
    page: Loadable({
      loader: () => import("./pages/business"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "business",
    head: {
      title: "SKOOTAR | ส่งเอกสาร ส่งพัสดุ ส่งอาหาร ในกทม. และปริมณฑล",
      meta: [
        {
          name: "description",
          content:
            "พ.ค. 21, 2024 - SKOOTAR บริการแมสเซ็นเจอร์ส่งเอกสารด่วนกทม. และปริมณฑล ส่งพัสดุด่วน ส่งสินค้า บริการรถกระบะขนของ ส่งอาหารเดลิเวรี่ ในกทม. และปริมณฑล",
        },
        {
          name: "keywords",
          content:
            "SKOOTAR, ขนส่งเพื่อธุรกิจ, บริษัทขนส่งสินค้า, ส่งเอกสาร, ส่งพัสดุ, ส่งอาหารเดลิเวรี, รถกระบะขนของ, แมสเซ็นเจอร์ส่งเอกสาร, บริการรับเช็ควางบิล, ส่งเอกสารด่วนกทม, รถกระบะรับจ้าง, แอพส่งของ",
        },
      ],
    },
  },
  ,
  {
    site: "/customer-tiers",
    path: `/:lang${langPattern}?/customer-tiers`,
    page: Loadable({
      loader: () => import("./pages/customerTiers"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "customer-tiers",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/news",
    path: `/:lang${langPattern}?/news`,
    page: Loadable({
      loader: () => import("./pages/news"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "news",
    head: {
      title: "News",
      meta: defaultMeta,
    },
  },
  {
    site: "/invoice",
    path: `/:lang${langPattern}?/invoice/:inv?`,
    page: Loadable({
      loader: () => import("./pages/invoice"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "invoice",
    head: {
      title: "Invoice",
      meta: defaultMeta,
    },
  },
  {
    site: "/favorite",
    path: `/:lang${langPattern}?/favorite`,
    page: Loadable({
      loader: () => import("./pages/favdriver"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "favorite",
    head: {
      title: "Favorite driver",
      meta: defaultMeta,
    },
  },
  {
    site: "/chat",
    path: `/:lang${langPattern}?/chat`,
    // page: Loadable({
    //   loader: () => import('./pages/chat'),
    //   loading: () => null,
    // }),
    permit: 1,
    routeKey: "chat",
    head: {
      title: "Chat",
      meta: defaultMeta,
    },
  },
  {
    site: "/settings",
    path: `/:lang${langPattern}?/settings`,
    page: Loadable({
      loader: () => import("./pages/settings"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "settings",
    head: {
      title: "Settings",
      meta: defaultMeta,
    },
  },
  {
    site: "/privacy",
    path: `/:lang${langPattern}?/privacy`,
    page: Loadable({
      loader: () => import("./pages/privacy"),
      loading: () => null,
    }),
    permit: 1,
    routeKey: "privacy",
    head: {
      title: "Privacy Policy",
      meta: defaultMeta,
    },
  },
  {
    site: "/pricing",
    path: `/:lang${langPattern}?/pricing`,
    page: Loadable({
      loader: () => import("./pages/pricing"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "pricing",
    head: {
      // title: "pricing",
      meta: [
        {
          name: "description",
          content:
            "รู้ราคาก่อนใช้งาน คิดราคาตามระยะทางจริง หลากหลายช่องทางการชำระเงิน จ่ายได้ทั้งเงินสด พร้อมเพย์ บัตรเครดิต เติมเงินผ่าน wallet วางบิลได้ มีเครดิตเทอมให้",
        },
        {
          name: "keywords",
          content:
            "SKOOTAR, สกู๊ตตาร์, แมสเซ็นเจอร์รายเดือน, แมสเซนเจอร์รายเดือน, ส่งเอกสารรายเดือน, ส่งพัสดุรายเดือน, เดินเอกสารรายเดือน, ส่งของรายเดือน, ส่งสินค้ารายเดือน, เก็บเช็ครายเดือน, วางบิลรายเดือน, ด่วน, กรุงเทพ, กทม, ส่งอาหารรายเดือน, ปริมณฑล, รายเที่ยว, รายครั้ง, รายเดือน, มอเตอร์ไซค์, บริการ, รับ, แอป, แอปพลิเคชัน, แอพพลิเคชั่น, ไทย, ดิลิเวอรี, Messenger, Logistics, document, parcel, collect cheque, deliver invoice, package, App, Application, on demand, Motorbike, Bangkok, BKK, Delivery, Service, Thailand, reliable, safe, เมสเซ็นเจอร์, มืออาชีพ, สมัครงาน, ไว้ใจได้",
        },
      ],
    },
  },
  {
    site: "/faq",
    path: `/:lang${langPattern}?/faq`,
    page: Loadable({
      loader: () => import("./pages/faq"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "faq",
    head: {
      meta: defaultMeta,
    },
  },
  {
    site: "/login",
    path: `/:lang${langPattern}?/login`,
    page: Loadable({
      loader: () => import("./pages/login"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "login",
    head: {
      title: "Login",
      meta: defaultMeta,
    },
  },
  {
    site: "/signup",
    path: `/:lang${langPattern}?/signup`,
    page: Loadable({
      loader: () => import("./pages/signup"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "signup",
    head: {
      title: "Signup",
      meta: defaultMeta,
    },
  },
  {
    site: "/termandconditions",
    path: `/:lang${langPattern}?/termandconditions`,
    page: Loadable({
      loader: () => import("./pages/termsandconditions"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "termandconditions",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/termsandconditions",
    path: `/:lang${langPattern}?/termsandconditions`,
    page: Loadable({
      loader: () => import("./pages/termsandconditions"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "termsandconditions",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/termsandconditions/robinhood",
    path: `/:lang${langPattern}?/termsandconditions/robinhood`,
    page: Loadable({
      loader: () => import("./pages/termRobinhood"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "termRobinhood",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/privacy",
    path: `/:lang${langPattern}?/privacy`,
    page: Loadable({
      loader: () => import("./pages/privacy"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "privacy",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/privacy-policy",
    path: `/:lang${langPattern}?/privacy-policy`,
    page: Loadable({
      loader: () => import("./pages/privacy"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "privacy-policy",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/driver-processor-agreement",
    path: `/:lang${langPattern}?/driver-processor-agreement`,
    page: Loadable({
      loader: () => import("./pages/driverPolicy"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "driver-processor-agreement",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },

  {
    site: "/cookies-policy",
    path: `/:lang${langPattern}?/cookie-policy`,
    page: Loadable({
      loader: () => import("./pages/Cookies"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "cookies-policy",
    head: {
      meta: [{ name: "robots", content: "noindex, nofollow" }, ...defaultMeta],
    },
  },
  {
    site: "/paymentPaysbuy",
    path: `/:lang${langPattern}?/paymentPaysbuy`,
    page: Loadable({
      loader: () => import("./pages/paymentpaysbuy"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "paymentpaysbuy",
    head: {
      meta: defaultMeta,
    },
  },
  {
    site: "/wallet",
    path: `/:lang${langPattern}?/wallet`,
    page: Loadable({
      loader: () => import("./pages/wallet"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "wallet",
    head: {
      title: "Wallet",
      meta: defaultMeta,
    },
  },
  {
    site: "/promotions",
    path: `/:lang${langPattern}?/promotions`,
    page: Loadable({
      loader: () => import("./pages/promotions"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "promotions",
    head: {
      title: "Promotions",
      meta: defaultMeta,
    },
  },
  // {
  //   site: '/FoodDelivery',
  //   path: `/:lang${langPattern}?/food-delivery-for-business`,
  //   page: Loadable({
  //     loader: () => import('./pages/fooddelivery'),
  //     loading: () => null,
  //   }),
  //   permit: 0,
  //   routeKey: 'FoodDelivery',
  //   head: {
  //     title: 'Food delivery for business',
  //     meta: defaultMeta,
  //   },
  // },
  {
    site: "/wallet",
    path: `/:lang${langPattern}?/wallet/:url?/:inv?`,
    page: Loadable({
      loader: () => import("./pages/wallet/skootarwallet"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "skootarwallet",
    head: {
      title: "Wallet",
      meta: defaultMeta,
    },
  },
  {
    site: "/signon",
    path: `/:lang${langPattern}?/signon`,
    page: Loadable({
      loader: () => import("./pages/signon"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "signon",
    head: {
      title: "SingOn",
      meta: defaultMeta,
    },
  },
  {
    site: "/findLocation",
    path: `/:lang${langPattern}?/findLocation`,
    page: Loadable({
      loader: () => import("./pages/findLocation"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "findlocation",
    head: {
      title: "Find Location",
      meta: defaultMeta,
    },
  },
  {
    site: "/routeOptimize",
    path: `/:lang${langPattern}?/routeOptimize`,
    page: Loadable({
      loader: () => import("./pages/routeOptimize"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "routeoptimize",
    head: {
      title: "Route Optimization",
      meta: defaultMeta,
    },
  },
  {
    site: "/createJob",
    path: `/:lang${langPattern}?/createJob`,
    page: Loadable({
      loader: () => import("./pages/createJob"),
      loading: () => null,
    }),
    permit: 0,
    routeKey: "createJob",
    head: {
      title: "Create Job",
      meta: defaultMeta,
    },
  },
  {
    site: "/feedbackUs",
    path: `/:lang${langPattern}?/neworder`,
    permit: 1,
    routeKey: "feedbackUs",
    head: {
      title: "Feedback Us",
      meta: defaultMeta,
    },
  },
];
