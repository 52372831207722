const host = window.location.origin;
const baseUrl = !!__BASE_URL__ ? `/${__BASE_URL__}` : ''; // eslint-disable-line no-undef

console.log('Test config global', { host, baseUrl });

const config = {
  secretkeyWallet: 'skootar-wallet',
  url: `${host}${baseUrl}`,
  prodClassicUrl: 'https://classic.skootar.com/',
  limitFiles: 5,
  maxSizeUpload: 3, // mb
  allowedExtensions: ['image/png', 'image/jpeg', 'image/jpg'],
  allowedExtensionsText: 'png, jpeg, jpg',
  // litmitIvoiceDate: 120, // days
  baseUrl,
  igMenu: [
    '^/$',
    '^[/](en|th)(/|)$',
    '^[/](en|th|)(/|)business(/|)$',
    '^[/](en|th|)(/|)biz(/|)$',
    '^[/](en|th|)(/|)pricing(/|)$',
    '^[/](en|th|)(/|)login(/|)$',
    '^[/](en|th|)(/|)(signupRefer|signuprefer)(/|)|(/)$',
    '^[/](en|th|)(/|)wallet(/|)$',
  ],
  hashContent: [
    '#download',
    '#whyskootar',
    '#serviceprice',
    '#howpay',
    '#faq',
    '#manual',
  ],
  keyPaymentType: {
    cash: 0,
    creditcard: 1,
    prepaid: 2,
    promptpay: 3,
    invoice: 4,
  },
  facebook: {
    options: {
      domain: 'connect.facebook.net',
      version: 'v6.0',
      cookie: false,
      status: false,
      xfbml: false,
      language: 'th',
      frictionlessRequests: false,
      appId: __DEV__ ? '1632365357058680' : '132755027075923', // eslint-disable-line no-undef
    },
  },
};
module.exports = config;
