export const lang = (state = null, action) => {
  switch (action.type) {
    case 'LANG_CHANGE':
      return {
        prevActiveLang: state ? state.currActiveLang : '',
        currActiveLang: action.state.currActiveLang,
      };
    default:
      return state;
  }
};
