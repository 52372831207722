import { initPrices, initPricesFullTime } from "../actions/orderAction";
let initPoints;
let initPointFullTime;
let initService;
let initStepIndex;
let initNeedReceipt;
let initConfrimPhone;
let initPackageId;
let initWorkDate;
let initHour;
let initMonthlyType;
let initPeriodType;
let initshowModal;
let initDetailFullTime;
let initPackageFullTime;

/** Change this 'mock = true' for testing */
const mock = false;
if (mock) {
  /** Mock */
  initPoints = [
    {
      // Mock 01
      addressId: 0,
      addressName: "point 1",
      address: "12",
      lat: 13.7392881,
      lng: 100.55784500000004,
      contactName: "1 2",
      contactPhone: "0877406644",
      remark: "",
      checkMe: false,
    },
    {
      // Mock 02
      addressId: 1,
      addressName: "point 2",
      address: "22",
      lat: 13.743907,
      lng: 100.58804350000003,
      contactName: "3 4",
      contactPhone: "0877406644",
      remark: "",
      checkMe: false,
    },
  ];
  initPointFullTime = [
    {
      // Mock 01
      addressId: 0,
      addressName: "point 1",
      address: "12",
      lat: 13.7392881,
      lng: 100.55784500000004,
      contactName: "1 2",
      contactPhone: "0877406644",
      remark: "",
      checkMe: false,
    },
  ];
  initPackageId = 1;
  initHour = 1;
  initMonthlyType = 50;
  initPeriodType = 1;
  initWorkDate = new Date();
  initshowModal = false;
  initDetailFullTime = {
    skNumber: 1,
    gender: "N",
    selectedOptions: "",
    optionsFee: 0,
    date: "",
    time: { beginTime: "", endTime: "" },
    OptionsArray: [],
    selectedStartTime: "",
  };
  initPackageFullTime = {
    periodType: "",
    packageHour: 4,
    diaryDays: [
      { label: "วันจันทร์", value: "วันจันทร์" },
      { label: "วันอังคาร", value: "วันอังคาร" },
      { label: "วันพุธ", value: "วันพุธ" },
      { label: "วันพฤหัสบดี", value: "วันพฤหัสบดี" },
      { label: "วันศุกร์", value: "วันศุกร์" },
    ],
    selectedPackage: "",
    packagePrice: 0,
  };
  initService = {
    vehicle: "Motorcycle",
    service: 2,
    extras: [1],
  };
  initStepIndex = {
    maxStep: 2,
    completed: 2,
    currentStep: 2,
    reqComplete: false,
  };
  initNeedReceipt = {
    check: undefined,
    companyName: undefined,
    companyAddress: undefined,
    taxNumber: undefined,
  };
  /** End Mock */
} else {
  /** Init Data */
  initPoints = [{}, {}];
  initPointFullTime = [
    {
      addressName: "",
      contactName: "",
      contactPhone: "",
      confirmPhone: "",
      address: "",
      addressId: 0,
      lat: 0,
      lng: 0,
    },
  ];
  initPackageId = null;
  initHour = 1;
  initMonthlyType = 50;
  initPeriodType = 1;
  initWorkDate = new Date();
  initshowModal = false;
  initDetailFullTime = {
    skNumber: 1,
    gender: "N",
    selectedOptions: "",
    optionsFee: 0,
    date: "",
    time: { beginTime: "", endTime: "" },
    OptionsArray: [],
    selectedStartTime: "",
  };
  initPackageFullTime = {
    periodType: "",
    packageHour: 4,
    diaryDays: [
      { label: "วันจันทร์", value: "วันจันทร์" },
      { label: "วันอังคาร", value: "วันอังคาร" },
      { label: "วันพุธ", value: "วันพุธ" },
      { label: "วันพฤหัสบดี", value: "วันพฤหัสบดี" },
      { label: "วันศุกร์", value: "วันศุกร์" },
    ],
    selectedPackage: "",
    packagePrice: 0,
  };
  initService = {
    vehicle: null,
    service: -1,
    extras: [],
  };
  initStepIndex = {
    maxStep: 2,
    completed: 0,
    currentStep: 0,
    reqComplete: false,
  };
  initNeedReceipt = {
    check: undefined,
    companyName: undefined,
    companyAddress: undefined,
    taxNumber: undefined,
  };
  initConfrimPhone = { confirmPhone: "" };
  /** End Init Data */
}

export const points = (state = initPoints, action) => {
  switch (action.type) {
    case "REORDER_POINTS": {
      return action.state;
    }
    default:
      return state;
  }
};

export const pointFullTime = (state = initPointFullTime, action) => {
  switch (action.type) {
    case "REORDER_POINTS_FULLTIME": {
      return action.state;
    }
    default:
      return state;
  }
};

export const services = (state = initService, action) => {
  switch (action.type) {
    case "UPDATE_SERVICES": {
      return action.state;
    }
    default:
      return state;
  }
};

export const servicesFullDay = (
  state = { vehicle: "Motorcycle", service: -1, extras: [] },
  action
) => {
  switch (action.type) {
    case "UPDATE_SERVICES_FULLDAY": {
      return action.state;
    }
    default:
      return state;
  }
};

export const packageId = (state = initPackageId, action) => {
  switch (action.type) {
    case "UPDATE_PACKAGE": {
      return action.state;
    }
    default:
      return state;
  }
};

export const detailFullTime = (state = initDetailFullTime, action) => {
  switch (action.type) {
    case "UPDATE_DETAIL_FULLTIME": {
      return action.state;
    }
    default:
      return state;
  }
};

export const packageFullTime = (state = initPackageFullTime, action) => {
  switch (action.type) {
    case "UPDATE_PACKAGE_FULLTIME": {
      return action.state;
    }
    default:
      return state;
  }
};

export const modalFullTime = (state = initshowModal, action) => {
  switch (action.type) {
    case "UPDATE_MODAL_FULLTIME": {
      return action.state;
    }
    default:
      return state;
  }
};
export const workDate = (state = initWorkDate, action) => {
  switch (action.type) {
    case "UPDATE_WORKDATE": {
      return action.state;
    }
    default:
      return state;
  }
};

export const hourId = (state = initHour, action) => {
  switch (action.type) {
    case "UPDATE_HOUR": {
      return action.state;
    }
    default:
      return state;
  }
};

export const monthlyType = (state = initMonthlyType, action) => {
  switch (action.type) {
    case "UPDATE_MONTHLY": {
      return action.state;
    }
    default:
      return state;
  }
};
export const period = (state = initPeriodType, action) => {
  switch (action.type) {
    case "UPDATE_PERIOD": {
      return action.state;
    }
    default:
      return state;
  }
};
export const stepIndex = (state = initStepIndex, action) => {
  switch (action.type) {
    case "MOVE_STEP": {
      return {
        ...state,
        // currentStep: action.state.to,
        requestStep: action.state.to,
        currentStep:
          action.state.to <= state.currentStep
            ? action.state.to
            : state.currentStep,
        reqComplete: action.state.to > state.currentStep,
        // completed: state.completed,
        // get completed() {
        //   return state.completed;
        // },
      };
    }
    case "ROLLBACK_STEP": {
      return {
        ...state,
        ...action.state,
      };
    }
    case "MOVE_STEP_NEXT": {
      return {
        ...state,
        requestStep: state.currentStep + 1,
        reqComplete: true,
      };
    }
    case "MOVE_STEP_BACK": {
      return {
        ...state,
        currentStep: state.currentStep - 1,
        reqComplete: false,
      };
    }
    case "STEP_VALIDATION_FAIL": {
      return {
        ...state,
        reqComplete: false,
      };
    }
    case "STEP_VALIDATION_COMPLETE": {
      return {
        ...state,
        currentStep:
          state.maxStep - 1 === state.currentStep
            ? state.currentStep
            : state.requestStep,
        reqComplete: false,
        get completed() {
          return state.completed < this.currentStep
            ? this.currentStep
            : state.completed;
        },
      };
    }
    case "STEP_FINAL_VALIDATION_COMPLETE": {
      return {
        ...state,
        currentStep: state.maxStep - 1,
        reqComplete: false,
        completed: state.maxStep,
      };
    }
    case "STEP_CEALR": {
      return {
        maxStep: 2,
        completed: 0,
        currentStep: 0,
        reqComplete: false,
      };
    }
    default:
      return state;
  }
};

export const orderMode = (state = { mode: 0 }, action) => {
  // console.log(action.state, action.type);
  switch (action.type) {
    // ENTER_ORDER_MODE
    // CONFIRM_SEARCH_LOCATION
    // ENTER_SEARCH_LOCATION_MODE
    case "RESET_ORDER_MODE": {
      return action.state;
    }
    case "ENTER_ORDER_MODE": {
      return action.state;
    }
    case "CONFIRM_SEARCH_LOCATION": {
      return action.state;
    }
    case "ENTER_SEARCH_LOCATION_MODE": {
      return action.state;
    }
    case "PAYMENT_ORDER_MODE": {
      return action.state;
    }
    case "ENTER_SEARCH_LOCATION_MODE_FULLTIME": {
      return action.state;
    }
    default:
      return state;
  }
};

export const orderData = (state = {}, action) => {
  switch (action.type) {
    case "ORDER_COMPLETE": {
      return action.data;
    }
    case "ORDER_FAIL": {
      return action.data;
    }
    default:
      return state;
  }
};

const x = (orderWindow, priceWindow) =>
  Math.abs(
    (priceWindow.isOpen ? priceWindow.width : 0) -
      (orderWindow.isOpen ? orderWindow.width : 0)
  );
const y = (orderWindow) =>
  Math.abs(orderWindow.isOpen ? orderWindow.height : 0);

export const offsetMap = (
  state = {
    priceWindow: { isOpen: false, width: 0 },
    orderWindow: { isOpen: false, width: 0, height: 0 },
    searchWindow: { isOpen: false, width: 0, height: 0 },
    get x() {
      x(this.orderWindow, this.priceWindow);
    },
    get y() {
      y(this.orderWindow);
    },
  },
  action
) => {
  const obj = { ...state, ...action.state };
  const listObj = {
    ...obj,
    get x() {
      return x(obj.orderWindow, obj.priceWindow);
    },
    get y() {
      return y(obj.orderWindow);
    },
  };
  switch (action.type) {
    case "UPDATE_OFFSET_MAP": {
      return listObj;
    }
    case "UPDATE_OFFSET_MAP_PRICE_WINDOW": {
      return listObj;
    }
    case "UPDATE_OFFSET_MAP_SEARCH_WINDOW": {
      return listObj;
    }
    case "UPDATE_OFFSET_MAP_ORDER_WINDOW": {
      console.log("UPDATE_OFFSET_MAP_ORDER_WINDOW");
      console.log(listObj.orderWindow);
      return listObj;
    }
    default:
      return state;
  }
};

export const estimatePrices = (state = initPrices, action) => {
  switch (action.type) {
    case "UPDATE_ESTIMATE_PRICES": {
      return {
        ...action.state,
      };
    }
    case "CLEAR_ESTIMATE_PRICES": {
      return initPrices;
    }
    default:
      return state;
  }
};

export const estimatePricesFullTime = (state = initPricesFullTime, action) => {
  switch (action.type) {
    case "UPDATE_ESTIMATE_PRICES_FULLTIME": {
      return {
        ...action.state,
      };
    }
    case "CLEAR_ESTIMATE_PRICES_FULLTIME": {
      return initPrices;
    }
    default:
      return state;
  }
};

export const promoCode = (state = "", action) => {
  switch (action.type) {
    case "UPDATE_PROMOCODE": {
      return action.state;
    }
    default:
      return state;
  }
};

const initorDerPayment = {
  creditCardLists: [],
  paymentType: false,
  fullDayPaymentType: false,
  paymentPointselected: false,
  creditCardselected: { key: false, data: {} },
};

export const orderPayment = (state = initorDerPayment, action) => {
  switch (action.type) {
    case "STEP_PAYMENT_TYPE_OPEN": {
      // creditCardLists: action.state.creditCardLists,
      return {
        ...state,
        ...action.state,
      };
    }
    case "ORDER_SELECT_PAYMENT_CARD": {
      // creditCardselected: action.state.creditCardselected,
      return {
        ...state,
        ...action.state,
      };
    }
    case "ADD_NEW_CARD": {
      // creditCardLists: action.state.creditCardLists
      return {
        ...state,
        ...action.state,
      };
    }
    case "SELECT_PAYMENT_TYPE": {
      return {
        ...state,
        paymentType: action.state.type,
      };
    }
    case "SELECT_FULLDAY_PAYMENT_TYPE": {
      return {
        ...state,
        fullDayPaymentType: action.state.type,
      };
    }
    case "SELECT_PAYMENT_POINT": {
      return {
        ...state,
        paymentPointselected: action.state.type,
      };
    }
    case "ORDER_CANCEL_PAYMENT_TYPE": {
      // paymentType: action.state.paymentType,
      // paymentPointselected: action.state.paymentPointselected,
      // creditCardselected: action.state.creditCardselected,
      return {
        ...state,
        ...action.state,
      };
    }
    default:
      return state;
  }
};

export const orderNeedReceipt = (state = initNeedReceipt, action) => {
  switch (action.type) {
    case "CLEAR_NEED_RECEIPT":
      return {
        ...initNeedReceipt,
      };
    case "CHECK_NEED_RECEIPT":
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
};

export const orderConfirmPhone = (state = initConfrimPhone, action) => {
  switch (action.type) {
    case "CLEAR_CONFIRM_PHONE":
      return {
        ...initConfrimPhone,
      };
    case "CHECK_CONFIRM_PHONE":
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
};

export const orderDesLists = (
  state = { imageBase64: [], remark: "" },
  action
) => {
  switch (action.type) {
    case "ORDER_IMAGE_LISTS": {
      return {
        ...state,
        ...action.state,
      };
    }
    case "ORDER_DES_LISTS": {
      return {
        ...state,
        ...action.state,
      };
    }
    default:
      return state;
  }
};

const initOrderConfirm = {
  taskType: false,
  dateOrder: "",
  valueWorkTime: "",
  valueWorkEndTime: "",
};

export const orderConfirm = (state = initOrderConfirm, action) => {
  switch (action.type) {
    case "ORDER_TASK_TYPE_TIME": {
      return {
        ...state,
        ...action.state,
      };
    }
    case "UPDATE_ORDER_TIME": {
      return {
        ...state,
        ...action.state,
      };
    }
    case "CLEAR_ORDER_TIME": {
      return initOrderConfirm;
    }
    default:
      return state;
  }
};

export const isOptimizedRoute = (state = false, action) => {
  switch (action.type) {
    case "UPDATE_OPTIMIZED_ROUTE": {
      return action.state;
    }
    default:
      return state;
  }
};

export const pointsBeforeOptimized = (state = initPoints, action) => {
  switch (action.type) {
    case "UPDATE_POINTS_BEFORE_OPTIMIZED": {
      return action.state;
    }
    default:
      return state;
  }
};
