export const loginFail = () => ({
  type: 'LOGIN_FAIL',
});
export const logout = () => ({
  type: 'LOGOUT',
});
export const updateUser = user => ({
  type: 'UPDATE_USER',
  state: user,
});
export const updateAuth = data => ({
  type: 'UPDATE_USER_AUTH',
  auth: data,
});
