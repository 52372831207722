export const loadingBar = (state = { loading: false }, action) => {
  switch (action.type) {
    case 'BEGIN_LOADING':
      return {
        loading: true,
      };
    case 'END_LOADING':
      return {
        loading: false,
        error: action.state.error,
      };
    default:
      return state;
  }
};

export const loadingBody = (state = { loading: false }, action) => {
  switch (action.type) {
    case 'BODY_LOADING':
      return {
        loading: true,
      };
    case 'END_BODY_LOADING':
      return {
        loading: false,
        error: action.state.error,
      };
    default:
      return state;
  }
};
