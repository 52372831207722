export const beginLoading = () => ({
  type: 'BEGIN_LOADING',
});
export const endLoading = (error = null) => ({
  type: 'END_LOADING',
  state: {
    error,
  },
});

export const bodyLoading = () => ({
  type: 'BODY_LOADING',
});
export const endBodyLoading = (error = null) => ({
  type: 'END_BODY_LOADING',
  state: {
    error,
  },
});
