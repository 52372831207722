import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import store from './store';
const localeSupport = require('../locale/locale.json');

export const defaultLang = () => {
  return localeSupport.default;
};

export const activeLang = () => {
  const locale = store.getState().locale;
  if (locale) {
    return getActiveLanguage(locale).code;
  }
  return localeSupport.default;
};

export const isDefaultLang = () => {
  return defaultLang() === activeLang();
};

export const appendWithLang = (str, transformResult = (result) => result) => {
  let newStr = str;
  const lang = activeLang();
  if (!!lang && lang !== localeSupport.default) {
    newStr = `${str}${lang}`;
  }
  return transformResult(newStr);
};

export default (text, format = null) => {
  const translations = store.getState().locale.translations;
  if (!!translations[text]) {
    // const values = [...translations[text]];
    // const indexOfLang = [...localeSupport.support].indexOf(lang);
    // let result = '';
    // if (indexOfLang > -1) {
    //   result = values[indexOfLang];
    // } else {
    //   result = text;
    // }
    // return result;
    const translate = getTranslate(store.getState().locale);
    return translate(text, format);
  }
  return text;
};
