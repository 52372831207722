export const user = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return action.state;
    case 'LOGIN_FAIL':
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

const initAuth = {
  userAuth: false,
  token: window.localStorage.getItem('token'),
  objUser: {},
  popup: false,
  state: 'login',
  data: { phone: '', email: '' },
  type: 'login',
};

export const userAuth = (state = initAuth, action) => {
  switch (action.type) {
    case 'UPDATE_USER_AUTH':
      return {
        ...state,
        ...action.auth,
      };
    default:
      return {
        ...state,
      };
  }
};
