const defaultMenu = {
  currentMenu: 1,
};

export const SelectedTabBar = (state = defaultMenu, action) => {
  switch (action.type) {
    case "EXPRESS_DELIVERY":
      return { ...state, currentMenu: 1 };
    case "FULL_DAY":
      return { ...state, currentMenu: 2 };
    case "NEXT_DAY":
      return { ...state, currentMenu: 3 };
    default:
      return state;
  }
};
