import langGlobal from "./global.json";
import langLogin from "./login.json";
import langBusiness from "./business.json";
import langHome from "./home.json";
import langHomeV3 from "./homev3.json";
import langInvoice from "./invoice.json";
import langDashboard from "./dashboard.json";
import langOrder from "./order.json";
import langNotification from "./notification.json";
import langSetting from "./setting.json";
import langPayment from "./payment.json";
import langError from "./error.json";
import langRating from "./rating.json";
import langPricing from "./pricing.json";
import langWallet from "./wallet.json";
import langExcel from "./excel.json";
import langFullDay from "./fullDay.json";
import langPDPA from "./pdpa.json";
import langConsent from "./consent.json";
import langNewBusiness from "./newBusiness.json";
import langCustomerTier from "./customerTier.json";

export default {
    ...langBusiness,
    ...langGlobal,
    ...langLogin,
    ...langHome,
    ...langHomeV3,
    ...langInvoice,
    ...langDashboard,
    ...langOrder,
    ...langNotification,
    ...langSetting,
    ...langPayment,
    ...langError,
    ...langRating,
    ...langPricing,
    ...langWallet,
    ...langExcel,
    ...langFullDay,
    ...langPDPA,
    ...langConsent,
    ...langNewBusiness,
    ...langCustomerTier,
};
