import store from "../core/store";
import { activeLang } from "../core/translate";
// const vehicleType = "Sedan";

export const getIconJobOptionUrl = (joptVal = 1) => {
  return `https://firebasestorage.googleapis.com/v0/b/skootar-project.appspot.com/o/assets%2Fimages%2FserviceOption%2Fjopt_${joptVal}.png?alt=media&token=75a6da6e-ee1b-4b20-aa05-8cd3181aaf63`;
};
export default class packageFullTimeList extends Array {
  constructor() {
    super();
    Object.assign(this, this.getServices());
  }

  getServices = (vehicleType) => {
    const packageFullTimeList = store.getState().initData;
    const rawPackages = !!packageFullTimeList.packageFullTimeData
      ? packageFullTimeList.packageFullTimeData
      : [];
    const lang = activeLang();
    let packageServicesInit;
    if (rawPackages) {
      packageServicesInit = rawPackages
        .filter((item) => item.id === vehicleType)
        .map((item) => {
          const allDuration = item.packages.map((item) => item.duration);
          const uniqueDuration = allDuration
            .filter((x, i, a) => a.indexOf(x) === i)
            .sort((a, b) => a - b);
          return {
            type: item.id,
            typeLabel: lang === "en" ? item.name_en : item.name_th,
            typeDesc: lang === "en" ? item.desc_en : item.desc_th,
            imageUrl: item.image_url,
            allType: uniqueDuration,
            get deliveries() {
              const deli = { ...item.packages };
              const packageArr = [];
              for (const [key, value] of Object.entries(deli)) {
                packageArr.push({
                  // id: value.fulltime_pkg_id,
                  id: value.fullday_pkg_id,
                  type: value.duration,
                  name:
                    lang === "en"
                      ? value.package_name_en
                      : value.package_name_th,
                  desc: lang === "en" ? value.desc_en : value.desc_th,
                  max_km: value.max_km,
                  customer_fee: value.customer_fee,
                  get getOptions() {
                    const opt = { ...value.options };
                    const optArr = [];
                    for (const [key, value] of Object.entries(opt)) {
                      optArr.push({
                        id: value.id,
                        desc: lang === "en" ? value.desc_en : value.desc_th,
                        descTh: value.desc_th,
                        descEn: value.desc_en,
                        fee: value.customer_fee,
                        remark:
                          lang === "en" ? value.remark_en : value.remark_th,
                      });
                    }
                    return optArr;
                  },
                });
              }
              // for (const [key, value] of Object.entries(deli)) {
              //   value.map((item2) =>
              //     packageArr.push({
              //       type: key,
              //       id: item2.fulltime_pkg_id,
              //       name:
              //         lang === "en"
              //           ? item2.package_name_en
              //           : item2.package_name_th,
              //       max_km: item2.max_km,
              //       customer_fee: item2.customer_fee,
              //       get getOptions() {
              //         const opt = { ...item2.options };
              //         const optArr = [];
              //         for (const [key, value] of Object.entries(opt)) {
              //           optArr.push({
              //             id: value.id,
              //             desc: lang === "en" ? value.desc_en : value.desc_th,
              //             fee: value.customer_fee,
              //           });
              //         }
              //         return optArr;
              //       },
              //     })
              //   );
              // }

              return packageArr;
            },
          };
        });
    }
    if (packageServicesInit) {
      const jobServices = packageServicesInit.map((item, i) => {
        return {
          type: item.type,
          typeLabel: item.typeLabel,
          typeDesc: item.typeDesc,
          imageUrl: item.imageUrl,
          allType: item.allType,
          packages: item.deliveries.map((item2, index) => {
            return {
              id: item2.id,
              desc: item2.desc,
              packageName: item2.name,
              distance: item2.max_km,
              price: item2.customer_fee,
              type: item2.type,
              options: item2.getOptions.map((item3) => {
                return {
                  id: item3.id,
                  desc: item3.desc,
                  fee: item3.fee,
                  descTh: item3.descTh,
                  descEn: item3.descEn,
                  packageId: item2.id,
                  remark: item3.remark,
                  get icon() {
                    return getIconJobOptionUrl(item3.id || 1);
                  },
                };
              }),
            };
          }),
        };
      });
      return jobServices;
    }
    // return packageServicesInit;
  };
  getDataFromJobType = (vehicle, pkgId, extraValueArray = []) => {
    // if (!pkgId) {
    //   return {
    //     extras: [],
    //   };
    // }
    const vehicleInfo = this.getServices(vehicle);
    const JobInfo = vehicleInfo[0].packages.find((v) => v.id === pkgId);
    const extrasArr = [];
    if (extraValueArray.length > 0) {
      extraValueArray.forEach((v) => {
        const extraObj = JobInfo.options.find((item) => Number(item.id) === v);
        if (!!extraObj) {
          extrasArr.push(extraObj);
        }
      });
    }
    const result = {
      // ...vehicleInfo,
      // service: JobInfo,
      extras: extrasArr,
    };
    return result;
  };
  // getMockVehcile = () => {
  //   return serviceLists;
  // };
}
