import { getStorageSettings } from "./../helper/localStorage";

export const initData = (
  state = {
    appData: {},
    badgeNews: 0,
    jobServices: [],
    priceOptions: {},
    vehicleData: [],
    packageFullTimeData: [],
    // topBar: getStorageSettings('topBar'),
    topBar: false,
    outOfService: false
  },
  action
) => {
  switch (action.type) {
    case "UPDATE_INIT_DATA":
      return {
        ...state,
        appData: action.state.appData,
        badgeNews: action.state.badgeNews,
        jobServices: action.state.jobServices,
        priceOptions: action.state.priceOptions,
        vehicleData: action.state.vehicleData,
        packageFullTimeData: action.state.packageFullTimeData,
        outOfService: action.state.outOfService
      };
    case "UPDATE_TOP_BAR":
      return {
        ...state,
        topBar: action.state.topBar,
      };
    case "UPDATE_BADGE_NEWS":
      return {
        ...state,
        badgeNews: action.state,
      };
    default:
      return state;
  }
};
