export const initPrices = {
  total: 0,
  normal: 0,
  discount: 0,
  freeCredit: 0,
  other: 0,
  distance: 0,
  promoCode: "",
  promoDesc: "",
};
export const initPricesFullTime = {
  MultipleSkPrice: 0,
  optFee: 0,
  totalPrice: 0,
};

export const reorderPoints = (points) => ({
  type: "REORDER_POINTS",
  state: points,
});
export const reorderPointsFullTime = (point) => ({
  type: "REORDER_POINTS_FULLTIME",
  state: point,
});
export const moveStep = (to) => ({
  type: "MOVE_STEP",
  state: { to },
});
export const rollbackStep = (state = { currentStep: 0, completed: 0 }) => ({
  type: "ROLLBACK_STEP",
  state,
});
export const moveStepNext = () => ({
  type: "MOVE_STEP_NEXT",
  // state: {
  //   currentStep,
  // },
});
export const moveStepBack = () => ({
  type: "MOVE_STEP_BACK",
  // state: {
  //   currentStep,
  // },
});
export const stepValidationFail = () => ({
  type: "STEP_VALIDATION_FAIL",
  // state: {
  //   currentStep,
  // },
});
export const stepValidationComplete = () => ({
  type: "STEP_VALIDATION_COMPLETE",
  // state: {
  //   currentStep,
  // },
});
export const stepFinalValidationComplete = () => ({
  type: "STEP_FINAL_VALIDATION_COMPLETE",
  // state: {
  //   currentStep,
  // },
});
export const stepClear = () => ({
  type: "STEP_CEALR",
});
export const updateOffsetMap = (state) => ({
  type: "UPDATE_OFFSET_MAP",
  state,
});
export const updateOffsetMapPriceWindow = (obj) => ({
  type: "UPDATE_OFFSET_MAP_PRICE_WINDOW",
  state: {
    priceWindow: { ...obj },
  },
});
export const updateOffsetMapSearchWindow = (obj) => ({
  type: "UPDATE_OFFSET_MAP_SEARCH_WINDOW",
  state: {
    searchWindow: { ...obj },
  },
});
export const updateOffsetMapOrderWindow = (obj) => ({
  type: "UPDATE_OFFSET_MAP_ORDER_WINDOW",
  state: {
    orderWindow: { ...obj },
  },
});
export const stepDataComplete = (step, data) => ({
  type: "STEP_DATA_COMPLETE",
  state: { step, data },
});
export const orderComplete = (data) => ({
  type: "ORDER_COMPLETE",
  state: data,
});
export const orderFail = (data) => ({
  type: "ORDER_FAIL",
  state: data,
});
export const resetOrderMode = () => ({
  type: "RESET_ORDER_MODE",
  state: {
    mode: 0,
  },
});
export const enterOrderMode = (point, index) => ({
  type: "ENTER_ORDER_MODE",
  state: {
    mode: 0,
    point,
    index,
  },
});
export const comfirmSearchLocation = (point, index) => ({
  type: "CONFIRM_SEARCH_LOCATION",
  state: {
    mode: 0,
    point,
    index,
  },
});
export const enterSearchLocationMode = (point, index) => ({
  type: "ENTER_SEARCH_LOCATION_MODE",
  state: {
    mode: 1,
    point,
    index,
  },
});

export const enterSearchLocationModeFullTime = (point, index) => ({
  type: "ENTER_SEARCH_LOCATION_MODE_FULLTIME",
  state: {
    mode: 1,
    point,
    index,
  },
});

export const enterPaymentMode = (step, mode) => ({
  type: "ENTER_ORDER_MODE",
  state: {
    mode,
    step,
  },
});

export const enterPaymentType = (data) => ({
  type: "STEP_PAYMENT_TYPE_OPEN",
  state: {
    creditCardLists: data.creditCardLists,
  },
});

export const slectPaymentPoint = (type) => ({
  type: "SELECT_PAYMENT_POINT",
  state: {
    type,
  },
});

export const addNewCreditCard = (data) => ({
  type: "ADD_NEW_CARD",
  state: {
    ...data,
  },
});

export const selectOrderPaymentCard = (data) => ({
  type: "ORDER_SELECT_PAYMENT_CARD",
  state: {
    ...data,
  },
});
export const selectPaymentType = (type) => ({
  type: "SELECT_PAYMENT_TYPE",
  state: {
    type,
  },
});

export const selectFullDayPaymentType = (type) => ({
  type: "SELECT_FULLDAY_PAYMENT_TYPE",
  state: {
    type,
  },
});

export const cancelPayment = (data) => ({
  type: "ORDER_CANCEL_PAYMENT_TYPE",
  state: {
    ...data,
  },
});

export const updateEstimatePrices = (prices) => ({
  type: "UPDATE_ESTIMATE_PRICES",
  state: prices,
});

export const clearEstimatePrices = () => ({
  type: "CLEAR_ESTIMATE_PRICES",
});

export const updateEstimatePricesFullTime = (prices) => ({
  type: "UPDATE_ESTIMATE_PRICES_FULLTIME",
  state: prices,
});

export const clearEstimatePricesFullTime = () => ({
  type: "CLEAR_ESTIMATE_PRICES_FULLTIME",
});

export const updatePromoCode = (code) => ({
  type: "UPDATE_PROMOCODE",
  state: code,
});

export const addOrderPhotoLists = (list) => ({
  type: "ORDER_IMAGE_LISTS",
  state: {
    imageBase64: list,
  },
});

export const addOrderDesLists = (remark) => ({
  type: "ORDER_DES_LISTS",
  state: {
    remark,
  },
});

export const updateServices = (services) => ({
  type: "UPDATE_SERVICES",
  state: services,
});

export const updateServicesFullDay = (services) => ({
  type: "UPDATE_SERVICES_FULLDAY",
  state: services,
});

export const updateTaskTypeTime = (type) => ({
  type: "ORDER_TASK_TYPE_TIME",
  state: type,
});

export const updateOrderTime = (date) => ({
  type: "UPDATE_ORDER_TIME",
  state: {
    dateOrder: date.dateOrder,
    valueWorkTime: date.valueWorkTime,
    valueWorkEndTime: date.valueWorkEndTime,
  },
});

export const clearOrderTime = () => ({
  type: "CLEAR_ORDER_TIME",
});

export const checkNeedReceipt = (data) => ({
  type: "CHECK_NEED_RECEIPT",
  state: data,
});

export const clearNeedReceipt = () => ({
  type: "CLEAR_NEED_RECEIPT",
});

export const checkNeedConfrimPhone = (data) => ({
  type: "CHECK_CONFIRM_PHONE",
  state: data,
});

export const clearConfirmPhone = () => ({
  type: "CLEAR_CONFIRM_PHONE",
});
export const updatePackage = (packageId) => ({
  type: "UPDATE_PACKAGE",
  state: packageId,
});

export const updateWorkDate = (workDate) => ({
  type: "UPDATE_WORKDATE",
  state: workDate,
});
export const updateHour = (hour) => ({
  type: "UPDATE_HOUR",
  state: hour,
});
export const updateMonthlyType = (type) => ({
  type: "UPDATE_MONTHLY",
  state: type,
});
export const updatePeriodType = (type) => ({
  type: "UPDATE_PERIOD",
  state: type,
});
export const updateModalFullTime = (show) => ({
  type: "UPDATE_MODAL_FULLTIME",
  state: show,
});

export const updateDetailFullTime = (data) => ({
  type: "UPDATE_DETAIL_FULLTIME",
  state: data,
});
export const updatePackageFullTime = (data) => ({
  type: "UPDATE_PACKAGE_FULLTIME",
  state: data,
});
export const updateIsOptimizedRoute = (data) => ({
  type: "UPDATE_OPTIMIZED_ROUTE",
  state: data,
});
export const updatePointsBeforeOptimized = (points) => ({
  type: "UPDATE_POINTS_BEFORE_OPTIMIZED",
  state: points,
});
