import * as orderAction from "../actions/orderAction";
import * as userProfileAction from "../actions/userProfileAction";
import { batchActions } from "redux-batched-actions";
import store from "../core/store";
import { bodyLoading, endBodyLoading } from "./../actions/loadingAction";
import * as alertAction from "./../actions/alertAction";
import * as dashboardAction from "./../actions/dashboardAction";
import { TabMenuClick } from "./../actions/tabBarAction";
import { updateTopbar } from "./../actions/initDataAction";
import * as api from "./../api/api";
import translate from "./../core/translate";
import { setActiveLanguage } from "react-localize-redux";
import { getContact, validateContactPhone, formatJobIdWithDash } from "./../helper/utils";
import packageFulltimeList from "../constants/packageFullTimeList";
import moment from "moment";

export const loading = () => {
  store.dispatch(bodyLoading());
};

export const endLoading = () => {
  store.dispatch(endBodyLoading());
};

export const alertSuccess = (title, message = "") => {
  store.dispatch(alertAction.alertSuccess(title, message));
};

export const alertError = (title, message = "") => {
  store.dispatch(alertAction.alertError(title, message));
};

export const alertInfo = (title, message = "") => {
  store.dispatch(alertAction.alertInfo(title, message));
};

export const alertButton = (type, title, message = "") => {
  store.dispatch(alertAction.alertButton(type, title, message));
};

export const alertPanelConfirm = (status) => {
  store.dispatch(alertAction.alertPanelConfirm(status));
};

export const alertConfirmDialog = ({
  title,
  message = "",
  callbackSubmit = () => { },
  callbackCancel = () => { },
  primary = true,
  txtBtnSubmit,
  txtBtnCancel,
  attrValueModal,
  attrValueSpan,
  attrValueBtn,
}) => {
  store.dispatch(
    alertAction.alertConfirm(
      () => {
        store.dispatch(alertAction.closeConfirm());
        callbackSubmit();
      },
      () => {
        store.dispatch(alertAction.closeConfirm());
        callbackCancel();
      },
      title,
      message,
      primary,
      txtBtnSubmit,
      txtBtnCancel,
      attrValueModal,
      attrValueSpan,
      attrValueBtn
    )
  );
};

export const cancelJob = (
  jobId,
  callback = () => { },
  title = translate("dashboard.dialog.cancelJob"),
  message = translate("dashboard.dialog.cancelJobDesc", { jobId: formatJobIdWithDash(jobId) })
) => {
  alertConfirmDialog({
    title,
    message,
    callbackSubmit: () => {
      api
        .cancelJob(jobId, "customer cancel")
        .then((result) => {
          if (result.data.responseCode === 200) {
            store.dispatch(
              batchActions([
                dashboardAction.setExpandData(null),
                alertAction.alertSuccess(
                  translate("notification.titleSuccess"),
                  translate("dashboard.dialog.cancelCompleted", { jobId: formatJobIdWithDash(jobId) })
                ),
                alertAction.closeConfirm(),
              ])
            );
            callback();
          } else {
            store.dispatch(
              alertAction.alertError(
                translate("Somthing wrong. Please try again")
              )
            );
          }
        })
        .catch((err) => {
          store.dispatch(alertAction.alertError(err));
        });
    },
    primary: false,
  });
};

export const dispatchDuplicateJob = (data, fnAfterDispatch = (v) => { }) => {
  loading();
  api
    .getJobDetail(data.jobId)
    .then((result) => {
      endLoading();
      const { responseCode, responseDesc, jobDetail } = result.data;
      if (responseCode === 200) {
        if (!!jobDetail) {
          console.warn(jobDetail);
          const points = jobDetail.locationList;
          const uniqeOption = jobDetail.jobOptions.filter(
            (v, i) => jobDetail.jobOptions.indexOf(v) === i
          );

          const services = {
            extras: uniqeOption.map(String),
            service: jobDetail.jobType,
            vehicle: jobDetail.vehicle,
          };
          if (uniqeOption.includes(3) || uniqeOption.includes(4)) {
            points.push({
              ...points[0],
              isReturn: true,
            });
          }
          const orderConfirmPhone = {
            isValidNumber: validateContactPhone(jobDetail.confirmPhone),
            duplicateJob: true,
            confirmPhone: jobDetail.confirmPhone,
          };

          // const startTime = jobDetail.jobTime.split(":");
          // const hourFunc = () => {
          //   const startTimeInt = parseInt(startTime[0], 10);
          //   if (durationHoursType === 1) {
          //     if (startTimeInt <= 15 && startTimeInt >= 8) {
          //       return startTimeInt;
          //     }
          //     return 15;
          //   }
          //   if (startTimeInt <= 12 && startTimeInt >= 8) {
          //     return startTimeInt;
          //   }
          //   return 12;
          // };

          if (!jobDetail.confirmPhone) {
            const { confirmPhone } = getContact();
            orderConfirmPhone.isValidNumber =
              validateContactPhone(confirmPhone);
            orderConfirmPhone.confirmPhone = confirmPhone;
          }
          new Promise((reslove) => {
            if (!jobDetail.fullDayInfo) {
              console.log(data.remarkImages);
              reslove(
                store.dispatch(
                  batchActions([
                    orderAction.resetOrderMode(),
                    orderAction.reorderPoints(points),
                    orderAction.updateServices(services),
                    orderAction.addOrderDesLists(data.remark),
                    orderAction.moveStep(1),
                    orderAction.stepValidationComplete(),
                    orderAction.addOrderPhotoLists([...data.remarkImages]),
                    orderAction.updatePromoCode(""), // clear promocode
                    orderAction.checkNeedConfrimPhone({ ...orderConfirmPhone }), // clear confrim phone
                    // TabMenuClick(2),
                  ])
                )
              );
            } else {
              const durationHoursType =
                jobDetail.fullDayInfo.durationHours === 4
                  ? 1
                  : jobDetail.fullDayInfo.durationHours === 9
                    ? 2
                    : 3;
              const fullDayOptFee = (optFee) => {
                const testArr = [];
                const initialValue = 0;
                if (optFee.length > 0) {
                  optFee.map((item) => testArr.push(item.customerFee));
                  const sumWithInitial = testArr.reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue,
                    initialValue
                  );
                  return sumWithInitial;
                }
                return 0;
              };

              const packageLists = new packageFulltimeList();
              const selectedVehicle = packageLists.getServices(
                jobDetail.vehicle
              );
              const selectedPackage = selectedVehicle[0].packages.filter(
                (item) => item.id === jobDetail.fullDayInfo.pkgId
              );
              const optFeePrice =
                selectedPackage.length === 0
                  ? 0
                  : fullDayOptFee(jobDetail.fullDayInfo.options);
              const fullDayPrices =
                selectedPackage.length === 0
                  ? {
                    MultipleSkPrice: 0,
                    optFee: 0,
                    totalPrice: 0,
                  }
                  : {
                    MultipleSkPrice: jobDetail.fullDayInfo.customerFee,
                    optFee: optFeePrice,
                    totalPrice:
                      jobDetail.fullDayInfo.customerFee + optFeePrice,
                  };

              const sumFeeFunc = (dataFee) => {
                const allOptFee = dataFee.map((item) => item.fee);
                const sumFee = allOptFee.reduce((a, b) => a + b, 0);
                return sumFee;
              };

              const allOpt =
                selectedPackage.length === 0 ? [] : selectedPackage[0].options;
              const filterOpt = () => {
                const optArr = [];
                for (let i = 0; i < allOpt.length; i++) {
                  for (let j = 0; j < uniqeOption.map(String).length; j++) {
                    if (allOpt[i].id === uniqeOption.map(String)[j]) {
                      optArr.push({
                        id: allOpt[i].id,
                        label: allOpt[i].desc,
                        labelTh: allOpt[i].descTh,
                        labelEn: allOpt[i].descEn,
                        fee: allOpt[i].fee,
                        value: i + 1,
                        icon: allOpt[i].icon,
                      });
                    }
                  }
                }
                return optArr;
              };
              const detailFullTime = {
                skNumber: 1,
                gender: jobDetail.fullDayInfo.messengerGender,
                selectedOptions: uniqeOption.map(String).join(","),
                optionsFee: sumFeeFunc(filterOpt()),
                date: "",
                // time: {
                //   beginTime: `${hourFunc()}:00`,
                //   endTime: `${
                //     hourFunc() + jobDetail.fullDayInfo.durationHours + 1
                //   }:00:00`,
                // },
                time: {
                  beginTime: ``,
                  endTime: ``,
                  // beginTime: ``,
                  // endTime: ``,
                },
                OptionsArray: filterOpt(),
                selectedStartTime: "",
              };
              const {
                contactName,
                contactPhone,
                lat,
                lng,
                addressId,
                addressName,
                address,
              } = jobDetail.locationList[0];
              const pointsFullTime = [
                {
                  addressName,
                  contactName,
                  contactPhone,
                  confirmPhone: jobDetail.confirmPhone,
                  address,
                  addressId,
                  lat,
                  lng,
                },
              ];
              reslove(
                store.dispatch(
                  batchActions([
                    orderAction.updateServices(services),
                    orderAction.updateHour(durationHoursType),
                    orderAction.updatePeriodType(durationHoursType),
                    orderAction.updatePackage(
                      selectedPackage.length !== 0
                        ? jobDetail.fullDayInfo.pkgId
                        : null
                    ),
                    orderAction.updateEstimatePricesFullTime(fullDayPrices),
                    orderAction.updateWorkDate(new Date()),
                    orderAction.reorderPointsFullTime(pointsFullTime),
                    orderAction.updateDetailFullTime(detailFullTime),
                    orderAction.addOrderDesLists(data.remark),
                    orderAction.updatePackageFullTime({
                      packageHour: durationHoursType,
                      selectedPackage: jobDetail.fullDayInfo.pkgId,
                      packagePrice: jobDetail.fullDayInfo.customerFee,
                      // diaryDays: [
                      //   { label: "วันจันทร์", value: "วันจันทร์" },
                      //   { label: "วันอังคาร", value: "วันอังคาร" },
                      //   { label: "วันพุธ", value: "วันพุธ" },
                      //   { label: "วันพฤหัสบดี", value: "วันพฤหัสบดี" },
                      //   { label: "วันศุกร์", value: "วันศุกร์" },
                      // ],
                    }),
                    TabMenuClick(2),
                  ])
                )
              );
            }
          }).then((v) => {
            fnAfterDispatch(v);
          });
        }
      } else {
        alertError({ message: responseDesc });
      }
    })
    .catch((err) => {
      endLoading();
      alertError({ message: translate("error.serverError") });
    });
};

export const dispatchNewEmptyJob = (fnAfterDispatch = (v) => { }) => {
  const { confirmPhone } = getContact();
  const orderConfirmPhone = {
    isValidNumber: validateContactPhone(confirmPhone),
    duplicateJob: false,
    confirmPhone,
  };
  new Promise((reslove) => {
    reslove(
      store.dispatch(
        batchActions([
          orderAction.reorderPoints([{}, {}]),
          orderAction.updateServices({
            vehicle: null,
            service: -1,
            extras: [],
          }),
          orderAction.moveStep(0),
          orderAction.stepClear(),
          orderAction.resetOrderMode(),
          orderAction.clearOrderTime(), // clear default select time now
          orderAction.selectPaymentType(false), // clear payment type selected
          orderAction.slectPaymentPoint(false), // clear location selected
          orderAction.clearEstimatePrices(),
          orderAction.addOrderPhotoLists([]), // clear photo
          orderAction.addOrderDesLists(""), // clear remark
          orderAction.updatePromoCode(""), // clear promocode
          orderAction.checkNeedConfrimPhone({ ...orderConfirmPhone }), // clear confrim phone
          TabMenuClick(1),
        ])
      )
    );
  }).then((v) => {
    fnAfterDispatch(v);
  });
};

export const dispatchPaymentType = (type) =>
  store.dispatch(orderAction.selectPaymentType(type));

export const dispatchNewLogin = (data) =>
  store.dispatch(userProfileAction.updateAuth({ ...data, popup: true }));

export const isMobile = () => store.getState().userAgent.isMobile;

export const setLang = (lang) => {
  const langChangeAct = {
    type: "LANG_CHANGE",
    state: {
      currActiveLang: lang,
    },
  };
  store.dispatch(batchActions([setActiveLanguage(lang), langChangeAct]));
};

// export const jobServices = () => store.getState().initData.jobServices;
export const vehicleServices = () => store.getState().initData.vehicleData;

export const updateTobar = (data) => store.dispatch(updateTopbar(data));
