export const defaultJobRatingDlg = {
  open: false,
  jobId: null,
  cbSubmit: null,
  tokenRating: null,
  isFromTipButton: false,
};
export const defaultTipDriverDlg = {
  open: false,
  phoneNumber: null,
  amount: 0,
  jobId: null,
  skootarId: null,
  skootarName: null,
  rating: null,
  isAlreadyFavDriver: false
};

export const defaultCustomerHelpDlg = {
  open: false,
  jobId: null,
};

export const defaultJobWaitingForRatingDlg = {
  open: false,
  data: [],
  cbClosed: null,
};

export const dialog = (
  state = {
    jobRatingDlg: defaultJobRatingDlg,
    tipDriverDlg: defaultTipDriverDlg,
    jobWaitingForRatingDlg: defaultJobWaitingForRatingDlg,
    customerHelpDlg: defaultCustomerHelpDlg,
  },
  action
) => {
  switch (action.type) {
    case 'OPEN_JOB_RATING': {
      return {
        ...state,
        jobRatingDlg: {
          open: true,
          jobId: action.state.jobId,
          rating: action.state.rating,
          cbSubmit: action.state.cbSubmit,
          tokenRating: action.state.tokenRating,
          isFromTipButton: action.state.isFromTipButton,
        },
      };
    }
    case 'CLOSE_JOB_RATING': {
      return {
        ...state,
        jobRatingDlg: defaultJobRatingDlg,
      };
    }
    case 'OPEN_TIP_DRIVER': {
      return {
        ...state,
        tipDriverDlg: {
          open: true,
          phoneNumber: action.state.phoneNumber,
          amount: action.state.amount,
          jobId: action.state.jobId,
          skootarId: action.state.skootarId,
          skootarName: action.state.skootarName,
          rating: action.state.rating,
          isAlreadyFavDriver: action.state.isAlreadyFavDriver,
        },
      };
    }
    case 'CLOSE_TIP_DRIVER': {
      return {
        ...state,
        tipDriverDlg: defaultTipDriverDlg,
      };
    }
    case 'OPEN_CUSTOMER_HELP': {
      return {
        ...state,
        customerHelpDlg: {
          open: true,
          jobId: action.state.jobId,
        },
      };
    }
    case 'CLOSE_CUSTOMER_HELP': {
      return {
        ...state,
        customerHelpDlg: defaultCustomerHelpDlg,
      };
    }
    case 'OPEN_JOB_WAITING_FOR_RATING': {
      return {
        ...state,
        jobWaitingForRatingDlg: {
          open: true,
          data: action.state.data,
          cbClosed: action.state.cbClosed,
        },
      };
    }
    case 'UPDATE_JOB_WAITING_FOR_RATING': {
      return {
        ...state,
        jobWaitingForRatingDlg: {
          open: action.state.open,
          data: action.state.data,
          cbClosed: action.state.cbClosed,
        },
      };
    }
    case 'CLOSE_JOB_WAITING_FOR_RATING': {
      return {
        ...state,
        jobWaitingForRatingDlg: defaultJobWaitingForRatingDlg,
      };
    }
    case 'REMOVE_ALL_DIALOG_DATA': {
      return {
        jobRatingDlg: defaultJobRatingDlg,
        customerHelpDlg: defaultCustomerHelpDlg,
      };
    }
    default:
      return state;
  }
};
