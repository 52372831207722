/**
 * To see how to customize theme at
 * http://www.material-ui.com/#/customization/themes
 *
 * To see what are the values you can override at
 * https://github.com/callemall/material-ui/blob/master/src/styles/getMuiTheme.js
 *
 * Color Tool
 * https://material.io/color/#!/?view.left=0&view.right=0
 */

import getMuiTheme from "material-ui/styles/getMuiTheme";

/**
 * font-family: 'Kanit', sans-serif;
 * font-family: 'Prompt', sans-serif;
 * font-family: 'Maitree', serif;
 * font-family: 'db_heaventmed';
 * font-family: 'db_heaventlight';
 * font-family: 'db_heaventheavy';
 * font-family: 'Roboto, sans-serif';
 */

const muiTheme = getMuiTheme({
  drawer: {
    width: 260,
    color: "#061645",
  },
  fontFamily: "Roboto, sans-serif",
  // fontFamily: 'db_heaventregular',
  palette: {
    primary1Color: "#28c3e3",
    primary2Color: "#5fedee",
    primary3Color: "#00a7a9",
    primary4Color: "#080860",
    accent1Color: "#ff6577",
    accent2Color: "#ff98a6",
    accent3Color: "#c7304b",
    textColorSecondary1: "#ff6577",
    textColorSecondary2: "#ff98a6",
    textColorSecondary3: "#c7304b",
    textColor: "#000",
    alternateTextColor: "#fff",
    alternateTextColor2: "#E0E0E0",
    alternateTextColor3: "#bcb5b9",
    backgroundUnChoose: "rgba(0, 0, 0, 0.2)",
    textColorDefault1: "rgba(0, 0, 0, 0.87)",
    textColorDefault2: "rgba(0, 0, 0, 0.54)",
    textColorDefault3: "rgba(0, 0, 0, 0.38)",
    bgSuccess: "#49ca85",
    bgError: "#f44336",
    bgInfo: "#2d93ec",
    bgWarning: "#ffa000",
  },
  appBar: {
    height: 50,
  },
  menuItem: {
    hoverColor: "rgba(0, 0, 0, 0.4)",
  },
  toggle: {
    thumbOffColor: "#f5f5f5",
    trackOffColor: "#bdbdbd",
    trackDisabledColor: "#bdbdbd",
  },
  button: {
    textTransform: "none",
  },
});

export default muiTheme;
