import jwt from 'jsonwebtoken';
import md5 from 'md5';
const cryptKey = 'react_skootar';
export const cryptJson = json => {
  const obj = jwt.sign(json, md5(cryptKey));
  return obj;
};

export const decryptJson = json => {
  const obj = jwt.verify(json, md5(cryptKey));
  delete obj.iat;
  return obj;
};
