import { getDateFromOffset } from '../helper/utils';

export const defaultJobListState = {
  dataList: [],
  page: 1,
  limit: 10,
  // selectedFilters: [0, 1, 2, 3],
  selectedFilters: [0, 1],
  sortDateBy: 'desc',
  sortStatusBy: 'asc',
  sortJobIdBy: 'none',
  searchText: '',
  searchSelected: null,
  showExpand: false,
  // fromDate: null,
  // toDate: null,
  fromDate: new Date(),
  toDate: new Date(),
  totalRecord: 0,
  forceListAllJobs: false,
  loading: false,
  modeDate: 'today'
};

export const dashboard = (state = {
  jobTableListState: defaultJobListState,
}, action) => {
  switch (action.type) {
    case 'SET_DASHBOARD_JOBLIST_STATE': {
      return {
        ...state,
        jobTableListState: {
          ...state.jobTableListState,
          ...action.state,
        },
      };
    }
    case 'FORCE_LIST_ALL_JOBS': {
      return {
        ...state,
        jobTableListState: {
          ...state.jobTableListState,
          forceListAllJobs: action.state,
        },
      };
    }
    case 'SET_EXPAND_DATA': {
      return {
        ...state,
        expandData: action.state,
      };
    }
    case 'SET_FIREBASE_JOB_SET': {
      return {
        ...state,
        firebaseJobSet: {
          ...action.state,
        },
      };
    }
    case 'REMOVE_ALL_DASHBOARD_DATA': {
      return {
        jobTableListState: defaultJobListState,
        expandData: null,
      };
    }
    default:
      return state;
  }
};
