import axios from "axios";
import moment from "moment";
import translate, { activeLang } from "../core/translate";
import { cryptJson, decryptJson } from "./crypt";
import * as utils from "../helper/utils";
import { isArray } from "util";
import * as reduxUtils from "../helper/reduxUtils";
import { settingStorage } from "../helper/localStorage";
import fire from "./../api/firebase";
import store from "../core/store";
import { updateInitData } from "../actions/initDataAction";
import * as eventTracking from "../helper/eventTracking";
/**  All API path
 * No need authentication checking.
  '/logout'
  '/login'
  '/resend_verify_code'
  '/verify_code'
  '/signup_mobile'
  '/get_job_detail_tracking'
  '/updateNewPassword'
  '/forgetPassword/${email}/web'
  '/get_suggest_place_list?query=${keyword}'
  '/estimate_price'
  '/init_data_v2'
  '/price_option'
  '/cc_omise_public_key'
  '/mkt_business_brochure'

* Need authentication checking.
  '/userInfo'
  '/get_list_location'
  '/create_new_job'
  '/get_current_job'
  '/get_completed_job'
  '/get_cancel_job'
  '/get_job_detail'
  '/update_user'
  '/invoice_not_pay'
  '/invoice_paid'
  '/job_rating'
  '/add_favorite_driver'
  '/add_block_driver'
  '/get_invoice_view'
  '/get_list_favorite_driver'
  '/get_customer_news'
  '/list_location'
  '/save_location'
  '/remove_location'
  '/remove_favorite_driver'
  '/api_update_fix_driver'
  '/cc_omise_create_customer'
  '/cc_omise_update_customer'
  '/cc_omise_list_cards'
  '/cc_omise_add_card_to_customer'
  '/cc_omise_update_card'
  '/cc_omise_delete_card'
  '/cc_omise_set_default_card'
  '/cc_omise_list_internetbanking'
  '/cc_omise_list_bill_payment'
  '/cc_omise_pay_invoice_via_internetbanking'
  '/cc_omise_pay_invoice_via_bill_payment'
  '/cc_omise_charge_invoice'
  '/customer_need_help'
  '/cancel_job'
 */

let CancelToken = axios.CancelToken;
let source = CancelToken.source();
let userToken = null;
let apiInstance = null;
// const channel = "web";
const getChannel = () => {
  const channel = utils.getChannel();
  return channel || "web";
};

/**
 * Initialize Api Instance
 **/
const getApi = () => {
  const user = utils.getContact();
  let token = null;
  if (!!user) {
    token = user.skToken;
  } else {
    const tokenSignup = utils.getDataSignUp();
    token = tokenSignup.skToken;
  }
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (!!token) {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Skootar-Token": token,
    };
  }
  if (!apiInstance || userToken !== token) {
    const newApi = axios.create({
      baseURL: __API_URL__,
      timeout: 60000,
      headers,
    });
    if (!!token) {
      userToken = token;
    }
    apiInstance = newApi;
    return newApi;
  }
  return apiInstance;
};

const getAuthenUser = () => {
  const user = utils.getContact();
  const authenUser = !!user
    ? {
      userName: user.userName,
      // password: user.password,
      facebookId: user.facebookId,
      facebookTokenId: user.facebookTokenId,
      lang: activeLang(),
      channel: getChannel(),
    }
    : { channel: getChannel() };
  return authenUser;
};

const promiseDelay = (task, delay) =>
  new Promise((resolve) => setTimeout(resolve, delay, task));

export const seq = (arrApi, delay = 100) =>
  arrApi.reduce(
    (promiseChain, currentTask) =>
      promiseChain.then((chainResults) =>
        promiseDelay(
          currentTask.then((currentResult) => [...chainResults, currentResult]),
          delay
        )
      ),
    Promise.resolve([])
  );

export const cancelRequest = (msg = "Cancel req by user.") => {
  source.cancel(msg);
  // Renew token
  CancelToken = axios.CancelToken;
  source = CancelToken.source();
};

export const responseCode = {
  success: "200",
};
/**
 * Handle Authentication Error like a Token expired, Ban, or something else about user authentication
 */
const getAuthenErrorFromRespArr = (resp) => {
  console.log("Test handleAuthenError -> getAuthenErrorFromRespArr", resp);
  const respErrAuthen = resp.find((r) => {
    if (!r.data.responseCode) {
      return false;
    }
    const code = +r.data.responseCode;
    switch (code) {
      case 130:
      case 131:
      case 132:
      case 133:
        return true;
      default:
        return false;
    }
  });
  return respErrAuthen;
};
const getAuthenErrorFromRespObj = (resp) => {
  if (!!resp.data.responseCode) {
    const code = +resp.data.responseCode;
    switch (code) {
      case 130:
      case 131:
      case 132:
      case 133:
        return resp;
      default:
        return null;
    }
  }
  return null;
};

/** Test expired error */
const fnForceExpiredTest = (resp) => ({
  ...resp,
  data: {
    responseCode: 130,
    responseDesc: "Token Expired Test",
  },
});

const handleAuthenError = (resp) => {
  let problemAuthen = null;

  try {
    if (isArray(resp)) {
      problemAuthen = getAuthenErrorFromRespArr(resp);
    } else {
      problemAuthen = getAuthenErrorFromRespObj(resp);
    }
  } catch (error) {
    console.error("error", resp);
  }

  if (!!problemAuthen) {
    if (typeof window !== "undefined") {
      if (
        ![
          "/",
          "/faq",
          "/news",
          "/business",
          "/pricing",
          // "/promotions",
          "/privacy",
          "/termsandconditions",
          "/signupRefer",
          "/login",
          "/signup",
        ].some((p) => p === window.location.pathname.toLowerCase())
      ) {
        reduxUtils.dispatchNewLogin({});
      }
    } else {
      utils.signOut().then((isLoggedOut) => {
        if (isLoggedOut) {
          window.location.href = "/login";
        }
      });
    }
  }
  return resp;
};

const handleCatchNoTokenErr = (err) => {
  const errResp = err.response;
  console.error("API Error", errResp);
  if (
    !!errResp &&
    !!errResp.data &&
    errResp.data.message.includes("Missing request header 'Skootar-Token'")
  ) {
    utils.signOut().then((isLoggedOut) => {
      if (isLoggedOut) {
        window.location.href = "/login";
      }
    });
  } else {
    console.error("App Error", err);
  }
};

// const apiExecute = (apiPromise) => apiPromise.then(handleAuthenError).catch(err => console.error(err.response));
const apix = {
  post: (...args) =>
    getApi()
      .post(...args)
      .catch(handleCatchNoTokenErr)
      .then(handleAuthenError),
  get: (...args) =>
    getApi()
      .get(...args)
      .catch(handleCatchNoTokenErr)
      .then(handleAuthenError),
  put: (...args) =>
    getApi()
      .put(...args)
      .catch(handleCatchNoTokenErr)
      .then(handleAuthenError),
};

// =================== Begin Firebase API ===================
const upLoadfileDataUrl = ({ file, pathfile, name }) => {
  const storageRef = fire.storage().ref();
  const uploadTask = storageRef.child(`${pathfile}/${name}`);
  return uploadTask.putString(file, "data_url").then((snapshot) => snapshot);
};

export const uploadPhotoOrder = (imageList, jobId) => {
  const pathFb = __DEV__
    ? `skootar_dev/JobImage/${jobId}/Images`
    : `skootar_prod/JobImage/${jobId}/Images`; // eslint-disable-line no-undef

  return Promise.all([
    ...imageList.map((file) =>
      upLoadfileDataUrl({ file: file.url, pathfile: pathFb, name: file.name })
    ),
  ]);
};
// =================== End Firebase API ===================

// =================== Begin PipeDrive API ===================
export const pipeData = {
  baseUrl: "https://skootar.pipedrive.com/v1",
  key: "04aa5f5c3e99982097af6d0eac736cbffa61cd9f",
};
export const getPipeDriveJson = () =>
  apix.get(
    "https://skootar.pipedrive.com/v1/organizationFields?api_token=04aa5f5c3e99982097af6d0eac736cbffa61cd9f"
  );

export const createOrganizationPipeDrive = (form) =>
  apix.post(
    `${pipeData.baseUrl}/organizations?api_token=${pipeData.key}`,
    form
  );

export const updateOrganizationPipeDrive = (id, industry) =>
  apix.put(
    `${pipeData.baseUrl}/organizations/${id}?api_token=${pipeData.key}`,
    industry
  );

export const createPersonPipeDrive = (form) =>
  apix.post(`${pipeData.baseUrl}/persons?api_token=${pipeData.key}`, form);

export const getPersonPipeDrive = (form) =>
  apix.get(
    `${pipeData.baseUrl}/persons/search?term=${form}&exact_match=true&api_token=${pipeData.key}`
  );

export const getOrganizationsPipeDrive = (form) =>
  apix.get(
    `${pipeData.baseUrl}/organizations/search?term=${form}&exact_match=true&api_token=${pipeData.key}`
  );
export const crateLeadsPipeDrive = (form) =>
  apix.post(
    `${pipeData.baseUrl}/leads?api_token=${pipeData.key}&b813d2be52318ae0aa399dd28a268abc80b6bb77=Webform`,
    form
  );
export const createNotePipeDrive = (form) =>
  apix.post(
    `${pipeData.baseUrl}/notes?api_token=${pipeData.key}&b813d2be52318ae0aa399dd28a268abc80b6bb77=Webform`,
    form
  );
export const getBusinessListPipeDrive = () =>
  apix.get(
    `${pipeData.baseUrl}/organizationFields/4020?api_token=${pipeData.key}&b813d2be52318ae0aa399dd28a268abc80b6bb77=Webform`
  );
export const createDealPipeDrive = (form, data) =>
  apix.post(`${pipeData.baseUrl}/deals?api_token=${pipeData.key}`, form);
// =================== End PipeDrive API ===================

// =================== Begin No need authentication checking API ===================
export const logout = () => {
  const req = {};
  return apix.post("/logout", JSON.stringify(req), {
    cancelToken: source.token,
  });
};
export const getVehicle = () => {
  const authenUser = getAuthenUser();
  const reqExtraServices = {
    ...authenUser,
    lang: activeLang(),
  };

  return apix.post("/v3/price_option", JSON.stringify(reqExtraServices), {
    cancelToken: source.token,
  });
};

export const getPriceOptionByLatLng = ({ lat, lng }) => {
  const authenUser = getAuthenUser();
  const reqExtraServices = {
    ...authenUser,
    lang: activeLang(),
    lat,
    lng
  };

  return apix.post("/v4/price_option", JSON.stringify(reqExtraServices), {
    cancelToken: source.token,
  });
}

export const getPackageFullTime = () => {
  const user = utils.getContact();
  const UserGroup =
    user.userGroup === undefined ||
      user.userGroup === null ||
      user.userGroup === ""
      ? "DEFAULT"
      : user.userGroup;
  // const UserGroup = "20211201_Normal1";
  const url = `fullday/api/v1/packages/${UserGroup}`;
  return apix.get(url);

  // const url = `https://dev.skootar.com/skootar_api_dev/fullday/api/v1/packages/DEFAULT`;
  // return axios({
  //   method: "get",
  //   url,
  // });
};
export const createFullTimeJob = (data) => {
  const user = utils.getContact();
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    ...data,
  };
  const url = "fullday/api/v1/create_job";
  return apix.post(url, req, {
    headers: {
      "Skootar-Token": user.skToken,
    },
  });
};
export const createFullTimeQuotation = (data) => {
  const user = utils.getContact();
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    ...data,
  };
  const url = "fullday/api/v1/quotation";
  return apix.post(url, req, {
    headers: {
      "Skootar-Token": user.skToken,
    },
  });
};

// export const jobServices = () => {
//   const mockOptions = {
//     1: {
//       descEng: "Document,Delivery document, collect cheque, delivery invoice",
//       descThai: "เอกสารทั่วไป,เก็บเช็ค,วางบิล",
//       fee: 0,
//       feeDesc: "+0฿",
//     },
//     2: {
//       descEng: "Deposit cheque after collecting",
//       descThai: "เก็บเงิน/เก็บเช็คปลายทาง แล้วนำเข้าบัญชี",
//       fee: 40,
//       feeDesc: "+40฿",
//     },
//     3: {
//       descEng: "Return trip",
//       descThai: "ไป-กลับ",
//       fee: 0.5,
//       feeDesc: "+50% (Max) 200 ฿",
//     },
//     4: {
//       descEng: "Collect cash on delivery and return immediately",
//       descThai: "เก็บเงินปลายทางแล้วนำมาส่ง",
//       fee: 0.5,
//       feeDesc: "+50% (Max) 200 ฿",
//     },
//     6: {
//       descEng: "Send post",
//       descThai: "ช่วยรับ-ส่งของที่รถตู้หรือไปรษณีย์ที่ท่านระบุ",
//       fee: 0,
//       feeDesc: "+0฿",
//     },
//     10: {
//       descEng: "Food Delivery Box",
//       descThai: "กล่องส่งอาหาร",
//       fee: 0,
//       feeDesc: "+0฿",
//     },
//   };
//   const {
//     1: opt1,
//     2: opt2,
//     3: opt3,
//     4: opt4,
//     6: opt6,
//     10: opt10,
//   } = mockOptions;
//   const mockJobServices = [
//     {
//       type: 1,
//       typeLabelEn: "Document",
//       typeLabelTh: "เอกสารทั่วไป",
//       typeDescEn: "Send documents",
//       typeDescTh: "ให้แมสฯ มารับ-ส่งเอกสารตามจุดที่ระบุ",
//       typeRemarkEn: "Motorcycle",
//       typeRemarkTh: "รถมอเตอร์ไซค์",
//       // options: { 1: opt1, 2: opt2, 4: opt4, 6: opt6 },
//     },
//     {
//       type: 3,
//       typeLabelEn: "Food",
//       typeLabelTh: "อาหาร",
//       typeDescEn: "Food delivery point to point",
//       typeDescTh: "ให้แมสฯ มารับ-ส่งอาหารตามจุดที่ระบุ",
//       typeRemarkEn: "Motorcycle\n All cakes not acceptable",
//       typeRemarkTh: "รถมอเตอร์ไซค์\n ไม่รับเค้กทุกประเภท",
//       // options: { 2: opt2, 4: opt4, 10: opt10 },
//     },
//     {
//       type: 2,
//       typeLabelEn: "Parcel",
//       typeLabelTh: "พัสดุ",
//       typeDescEn: "Motorcycle\n Max 50x50x80cm, 20kg",
//       typeDescTh: "รถมอเตอร์ไซด์\n ไม่เกิน 50x50x80ซม. 20กก.",
//       typeRemarkEn: "Motorcycle\n Max 50x50x80cm, 20kg",
//       typeRemarkTh: "รถมอเตอร์ไซด์\n ไม่เกิน 50x50x80ซม. 20กก.",
//       // options: { 1: opt1, 2: opt2, 4: opt4, 6: opt6, 10: opt10 },
//     },
//     {
//       type: 4,
//       typeLabelEn: "Large Parcel",
//       typeLabelTh: "พัสดุขนาดใหญ่",
//       typeDescEn: "Car\n Max 115x115x80cm, 100kg",
//       typeDescTh: "รถยนต์ไม่เกิน\n 115x115x80ซม. 100กก.",
//       typeRemarkEn: "Car\n Max 115x115x80cm, 100kg",
//       typeRemarkTh: "รถยนต์ไม่เกิน\n 115x115x80ซม. 100กก.",
//       // options: { 1: opt1, 2: opt2, 4: opt4, 6: opt6, 10: opt10 },
//     },
//   ];
//   const authenUser = getAuthenUser();
//   console.log("authenUser ", authenUser);
//   const reqExtraServices = {
//     ...authenUser,
//     lang: activeLang(),
//   };
//   return apix.post("/v2/price_option", JSON.stringify(reqExtraServices), {
//     cancelToken: source.token,
//     transformResponse: [
//       (raw) => {
//         let res = {
//           jobServices: mockJobServices,
//           priceOptions: mockOptions,
//         };
//         const optionList = JSON.parse(raw);
//         const user = utils.getContact();
//         console.log("user ", user);
//         // const showOptionItem = user.userGroup === null ? 4 : 3;
//         const showOptionItem = Object.keys(optionList).length;
//         console.log("showOptionItem ", showOptionItem);
//         if (Object.keys(optionList).length > 0) {
//           try {
//             const transformData = mockJobServices
//               .filter((v, k) => k < showOptionItem)
//               .map((service) => {
//                 const options = {};
//                 optionList[service.type].map((i) => {
//                   options[i.jobOptionId] = { ...i };
//                 });
//                 console.log(options);
//                 return {
//                   ...service,
//                   options,
//                 };
//               });
//             console.log(transformData);
//             res = { jobServices: transformData, priceOptions: optionList };
//           } catch (error) {
//             console.error(error);
//           }
//         }
//         return res;
//       },
//     ],
//   });
// };

export const login = ({ userName, password, ...oth }) => {
  const mergeBrowserDetect = utils.getBrowserVersion();
  const src = {
    userName,
    channel: getChannel,
    lang: activeLang(),
  };
  const req = { ...src, ...mergeBrowserDetect };
  if (!!oth.facebookId) {
    req.facebookId = oth.facebookId;
    req.facebookTokenId = oth.facebookTokenId;
  } else {
    req.password = password;
  }

  return apix
    .post("/login", JSON.stringify(req), { cancelToken: src.token })
    .then((res) => {
      if (res.data.respCode === "200") {
        // fire.auth().signInAnonymously();
        const {
          phoneNo,
          userId,
          userType,
          firstName,
          lastName,
          referCode,
          facebookId,
          facebookTokenId,
          companyId,
          companyName,
          companyAddress,
          companyTaxId,
          deviceId,
          paymentType,
          omiseCustomerId,
          fixDriver,
          skToken,
          userStatus,
          skootarWallet,
          freeCredit,
          invoiceEnabled,
          countJob,
          userGroup,
          createdDateTime,
          confirmPhone,
          fullDayPayment,
        } = res.data;
        // const userStatusBan = '-1';
        // if (userStatus === userStatusBan) {
        //   return res.data;
        // }
        let userComplete = false;
        if (!!firstName && !!lastName) userComplete = true;
        const userTypeCompany = 1;
        if (userType === userTypeCompany) {
          if (
            userComplete &&
            !!companyName &&
            !!companyTaxId &&
            !!companyAddress
          ) {
            userComplete = true;
          } else {
            userComplete = false;
          }
        }
        const obj = {
          userName: res.data.userName,
          phoneNo,
          userId,
          userType,
          // password,
          firstName,
          lastName,
          referCode,
          facebookId,
          facebookTokenId,
          companyId,
          companyName,
          companyAddress,
          companyTaxId,
          deviceId,
          paymentType,
          fullDayPayment,
          channel: getChannel(),
          omiseCustomerId,
          fixDriver,
          userStatus,
          skToken,
          skootarWallet,
          freeCredit,
          invoiceEnabled,
          countJob,
          userGroup,
          createdDateTime,
          confirmPhone,
          invoiceCompanyAddress: res.data.invoiceCompanyAddress,
          invoiceCompanyName: res.data.invoiceCompanyName,
          invoiceCompanyTaxNo: res.data.invoiceCompanyTaxNo,
        };
        window.localStorage.setItem("objUser", cryptJson(obj));
        Object.assign(res.data, { userComplete });
      } else if (res.data.respCode === "102") {
        window.localStorage.setItem(
          "cryptSignup",
          cryptJson({
            userName,
            password,
            facebookId: res.data.facebookId,
            facebookTokenId: res.data.facebookTokenId,
            skToken: res.data.skToken,
          })
        );
      }
      return res.data;
    })
    .then((data) => {
      // Promise.all([jobServices(), getBadgeNews(), userInfo()]).then(
      Promise.all(
        data.respCode === "200"
          ? [getBadgeNews(), getVehicle(), getPackageFullTime(), userInfo()]
          : [getBadgeNews(), getVehicle(), getPackageFullTime()]
      ).then(([badgeNews, vehicleList, packageList]) => {
        const { data: vehicleData } = vehicleList;
        const { data: packageFullTimeData } = packageList;
        settingStorage();
        store.dispatch(
          updateInitData({
            ...store.getState().initData,
            badgeNews,
            vehicleData,
            packageFullTimeData,
          })
        );
      });
      return data;
    });
};

export const loginVerify = () => {
  let data = window.localStorage.getItem("cryptSignup");
  try {
    data = decryptJson(data);
    const req = {
      userName: data.userName,
    };
    if (!!data.password) {
      req.password = data.password;
    } else {
      req.facebookId = data.facebookId;
      req.facebookTokenId = data.facebookTokenId;
    }

    return login(req).then((res) => res);
  } catch (error) {
    return { responseCode: 500 };
  }
};

export const resendVerifyCode = (userName, phone) =>
  apix.post(
    "/resend_verify_code",
    JSON.stringify({ userName, phoneNo: phone, lang: activeLang() }),
    {
      cancelToken: source.token,
      transformResponse: [
        (raw) => JSON.parse(raw),
        // const data = JSON.parse(raw);
        // console.log(data);
        // return data;
      ],
    }
  );

export const sendOTPCode = ({ userName, verifyCode }) => {
  const req = {
    userName,
    verifyCode,
  };

  return apix.post("/verify_code", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const saveKlook = (data) => {
  return apix.post("/klook/address/save", JSON.stringify(data), {
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const estimateKlook = (data) => {
  const form = { ...data };
  delete form.location[1].address;
  return apix.post("/klook/estimate", JSON.stringify(form), {
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const signUpCompanyFrm = (form) => {
  const mergeBrowserDetect = utils.getBrowserVersion();
  const req = { ...form, ...mergeBrowserDetect };
  return apix.post("/signup_mobile", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: (raw) => {
      window.localStorage.setItem("cryptSignup", cryptJson({ ...req }));
      return JSON.parse(raw);
    },
  });
};

export const consentPDPA = (userID, question2) => {
  const req = {
    userId: userID,
    question2,
  };
  return apix.post("/pdpa/customer", JSON.stringify(req), {
    cancelToken: source.token,
  });
};

export const signUpFrm = ({
  email,
  phone,
  password,
  referenceId = "",
  pdpaQuestion2 = 1,
}) => {
  window.localStorage.removeItem("cryptSignup");
  const mergeBrowserDetect = utils.getBrowserVersion();
  const src = {
    userName: email,
    password,
    phoneNo: phone,
    guid: window.localStorage.getItem("guid"),
    channel: getChannel(),
    referenceId,
    lang: activeLang(),
    userType: 2,
    pdpaQuestion2,
  };
  const req = { ...src, ...mergeBrowserDetect };
  console.log(req);
  return apix.post("/signup_mobile", JSON.stringify(req), {
    cancelToken: src.token,
    transformResponse: (raw) => {
      const obj = JSON.parse(raw);
      window.localStorage.setItem(
        "cryptSignup",
        cryptJson({ ...req, skToken: obj.skToken })
      );
      return obj;
    },
  });
};

export const signUpfb = ({
  email,
  firstName,
  lastName,
  phone,
  facebookId,
  facebookTokenId,
  password,
  referenceId = "",
}) => {
  const mergeBrowserDetect = utils.getBrowserVersion();
  const src = {
    userName: email,
    firstName,
    lastName,
    facebookId,
    facebookTokenId,
    phoneNo: phone,
    guid: window.localStorage.getItem("guid"),
    channel: getChannel(),
    password,
    referenceId,
    lang: activeLang(),
    userType: 2, // default person
  };
  const req = { ...src, ...mergeBrowserDetect };
  return apix.post("/signup_mobile", JSON.stringify(req), {
    cancelToken: src.token,
    transformResponse: [
      (raw) => {
        const obj = JSON.parse(raw);
        window.localStorage.setItem(
          "cryptSignup",
          cryptJson({ ...req, skToken: obj.skToken })
        );
        return obj;
      },
    ],
  });
};

export const getJobDetailTracking = (trackingId, lang = activeLang()) => {
  // const authenUser = getAuthenUser();
  const req = {
    channel: getChannel(),
    lang,
    trackingId,
  };
  return apix.post("/get_job_detail_tracking", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        const data = resp.jobDetail;
        const transformData = {
          responseCode: +resp.responseCode,
          responseDesc: resp.responseDesc,
          jobDetail: {
            ...data,
            jobDate: moment(data.jobDate, "DD/M/YYYY").format("DD/MM/YYYY"),
            rating: +data.raiting,
            jobType: +data.jobType,
            jobOptions: !!data.option
              ? data.option.split(",").map((v) => parseInt(v, 10))
              : [],
            jobStatusDesc: lang === "th" ? data.jobStatusTh : data.jobStatusEn,
          },
        };
        console.log(transformData);
        return transformData;
      },
    ],
  });
};

export const getJobDetailTrackingToken = (tokenRating, lang = activeLang()) => {
  // const authenUser = getAuthenUser();
  const req = {
    channel: getChannel(),
    lang,
    tokenRating,
  };
  return apix.post("/get_job_detail_tracking", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        const data = resp.jobDetail;
        const transformData = {
          responseCode: +resp.responseCode,
          responseDesc: resp.responseDesc,
          jobDetail: {
            ...data,
            jobDate: moment(data.jobDate, "DD/M/YYYY").format("DD/MM/YYYY"),
            rating: +data.raiting,
            jobType: +data.jobType,
            jobOptions: !!data.option
              ? data.option.split(",").map((v) => parseInt(v, 10))
              : [],
            jobStatusDesc: lang === "th" ? data.jobStatusTh : data.jobStatusEn,
          },
        };
        console.log(transformData);
        return transformData;
      },
    ],
  });
};

export const getJobDetailTrackingByC = (trackingId, lang = activeLang()) => {
  const req = {
    lang,
    trackingId,
    channel: getChannel(),
  };
  return apix.post("/get_job_detail_tracking_by_c", JSON.stringify(req), {
    cancelToken: source.token,
  });
};

export const forgotPwd = (email) => apix.get(`/forgetPassword/${email}/web`);

export const updatePwd = (userName, verifyCode, newPassword) => {
  // const authenUser = getAuthenUser();
  const req = {
    userName,
    verifyCode,
    newPassword,
  };
  return apix.post("/updateNewPassword", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const getSuggestionList = (keyword = "") => {
  if (keyword === "") {
    return new Promise(
      (resolve) =>
        resolve({
          data: {
            addressList: [],
          },
        }),
      (reject) => reject("error")
    );
  }
  return apix.get(`/get_suggest_place_list?query=${encodeURI(keyword)}`, {
    cancelToken: source.token,
  });
};

export const textSearch = (keyword = "") =>
  apix.get(`/text_search?query=${encodeURI(keyword)}`, {
    cancelToken: source.token,
  });

export const estimatePrices = (
  points,
  services = { vehicle: null, service: 1, extras: [] },
  promoCode = "",
  jobDate,
  startTime,
  finishTime
) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    vehicle: services.vehicle,
    jobType: services.service,
    option:
      services.extras.length > 0
        ? services.extras.reduce((total, v) => `${total},${v}`)
        : "",
    promoCode,
    locationList: points.map((v) => ({
      lat: v.lat,
      lng: v.lng,
    })),
    jobDate,
    startTime,
    finishTime,
  };
  return apix.post("/estimate_price", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        if (+data.responseCode === 500) {
          return {
            total: 0,
            normal: 0,
            discount: 0,
            freeCredit: 0,
            other: 0,
            promoCode: "",
            promoDesc: translate("error.serverError"),
            distance: 0,
          };
        }
        return {
          total: data.netPrice,
          normal: data.normalPrice,
          discount: data.discount,
          freeCredit: data.freeCredit,
          other: 0,
          promoCode: data.promoCode,
          get promoDesc() {
            let desc =
              activeLang() === "th"
                ? data.promoCodeDescTh
                : data.promoCodeDescEn;
            if (promoCode !== "" && desc === "") {
              desc = translate("error.promoExpired");
            }
            return desc;
          },
          distance: data.distance,
        };
      },
    ],
  });
};

export const omisePublicKey = () => {
  const req = {};
  return apix.post("/cc_omise_public_key", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const initAppData = (lang = activeLang()) => {
  const init = apix.get("/init_data_v3", { cancelToken: source.token });
  return Promise.all([
    init,
    omisePublicKey(),
    getBadgeNews(),
    getVehicle(),
    getPackageFullTime(),
  ]);
};

export const businessBrochure = (email) => {
  const req = { email, lang: activeLang() };
  const url = "/mkt_business_brochure";
  return apix.post(url, JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: (raw) => {
      return JSON.parse(raw);
    },
  });
};

// =================== End No need authentication checking API ===================

// =================== Begin Need authentication checking API ===================

export const userInfo = () => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
  };
  return apix
    .post("/userInfo", JSON.stringify(req), { cancelToken: source.token })
    .then((resp) => {
      const current = utils.getContact();
      const { companyAddress, companyName, companyTaxId, ...newData } =
        resp.data;
      const updatedUser = {
        ...current,
        ...newData,
        // password: current.password,
        invoiceCompanyAddress: companyAddress,
        invoiceCompanyName: companyName,
        invoiceCompanyTaxNo: companyTaxId,
        skToken: current.skToken,
      };
      if (resp.data.responseCode === "200") {
        utils.updateContact(updatedUser);
      } else if (resp.data.responseCode === "131") {
        window.localStorage.removeItem("objUser");
        window.location.reload();
      }
      try {
        eventTracking.send("user_info", resp.data);
      } catch (err) {
        // Do nothing.
      }
    });
};

export const getLocationList = (keyword = "") => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    keyword,
  };
  if (!!utils.getContact()) {
    return apix.post("/get_list_location", JSON.stringify(req), {
      cancelToken: source.token,
    });
  }
  const resp = {
    data: {
      responseCode: 100,
      responseDesc: "Login Failed",
    },
  };
  return new Promise(
    (resolve) => resolve(resp),
    (reject) => reject("error")
  );
};
export const setFavoriteLocation = (addressID, value) => {
  const user = utils.getContact();
  const req = {
    lang: "th",
    addressId: addressID,
    value,
  };
  return apix.post("/location/favorite", JSON.stringify(req), {
    headers: {
      "Skootar-Token": user.skToken,
    },
  });
};

export const getSearchList = (keyword = "") =>
  Promise.all([getLocationList(keyword), getSuggestionList(keyword)]);

export const createNewJob = (data) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    ...data,
  };
  return apix.post("/create_new_job", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const obj = JSON.parse(raw);
        if (+obj.responseCode === 200) {
          utils.updateContact({
            ...utils.getContact(),
            skootarWallet: obj.remainingWallet,
            paymentType: data.paymentType,
          });
        }
        return {
          ...obj,
          responseCode: +obj.responseCode,
        };
      },
    ],
  });
};

export const createJob = (data) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    ...data,
  };
  return apix.post("/create_job", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const obj = JSON.parse(raw);
        if (+obj.responseCode === 200) {
          utils.updateContact({
            ...utils.getContact(),
            skootarWallet: obj.skootarWallet,
            paymentType: req.paymentType,
          });
        }
        return {
          ...obj,
          responseCode: +obj.responseCode,
        };
      },
    ],
  });
};

export const getGeoHereMap = (location) => {
  const user = utils.getContact();
  const url = `search_address_by_location?lat=${location.lat}&lng=${location.lng}&lang=${activeLang()}`

  return apix.get(url, {
    headers: {
      "Skootar-Token": user.skToken,
    },
    cancelToken: source.token,
    transformResponse: [
      (res) => {
        const response = JSON.parse(res);
        const { address } = response.items[0];
        const { lat, lng } = response.items[0].position;
        const data = {
          lat: lat,
          lng: lng,
          address: address.label,
          addressName: address.label.split(",")[0],
          district: address.district ? address.district : "",
          subDistrict: address.subdistrict ? address.subdistrict : "",
        };
        return data;
      },
    ],
  });
};

export const listAllJobs = (fromDate = undefined, toDate = undefined) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    lang: authenUser.lang,
    fromDate: utils.formatDate(fromDate),
    toDate: utils.formatDate(toDate),
  };
  const transformResponse = [
    (raw) => {
      const data = JSON.parse(raw);
      console.log(data);
      if (!data.jobList) {
        return {
          ...data,
          jobList: [],
        };
      }
      const jobList = data.jobList.map((v) => ({
        ...v,
        jobDate: moment(v.jobDate, "DD/M/YYYY").format("DD/MM/YYYY"),
        jobStatusDesc: req.lang === "th" ? v.jobStatusTh : v.jobStatusEn,
        jobStatusDict: { th: v.jobStatusTh, en: v.jobStatusEn },
        rating: +v.raiting,
      }));
      return {
        ...data,
        jobList,
      };
    },
  ];
  const activeJobs = apix.post("/get_current_job", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse,
  });
  const completedJobs = apix.post("/get_completed_job", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse,
  });
  const canceledJobs = apix.post("/get_cancel_job", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse,
  });
  return Promise.all([activeJobs, completedJobs, canceledJobs]).then(
    ([respCurrent, respCompleted, respCanceled]) => {
      console.log("Test listAllJobs", {
        respCurrent,
        respCompleted,
        respCanceled,
      });
      return [respCurrent, respCompleted, respCanceled];
    }
  );
};
export const getEmailSMSNoti = (skToken, userID) => {
  return apix.get(`/user/setting/${userID}`, {
    headers: {
      "Skootar-Token": skToken,
    },
  });
};
export const ChangeEmailSMSNoti = (data, skToken) => {
  const req = {
    channel: "web",
    lang: "en",
    userId: data.userId,
    values: {
      recipient_sms: data.recipient_sms,
      recipient_email: data.recipient_email,
    },
  };
  return apix.post("/user/setting", JSON.stringify(req), {
    headers: {
      "Skootar-Token": skToken,
    },
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const downloadCSVJob = (query, cb) => {
  return apix
    .get(`/job/csv?${query}`, {
      headers: {
        Accept: "text/csv",
      },
      cancelToken: source.token,
    })
    .then((response) => {
      if (
        !!response.data.responseCode &&
        response.data.responseCode === "130"
      ) {
        alert(response.data.responseDesc);
      } else {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), response.data], {
            encoding: "UTF-8",
            type: "text/csv;charset=UTF-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        const nameDate = moment(new Date()).format("DD-MM-YYYY");
        link.setAttribute("download", `${nameDate}.csv`);
        document.body.appendChild(link);
        link.click();
        cb();
      }
    });
};

export const getListJobs = (query) => {
  const authenUser = getAuthenUser();

  const transformResponse = [
    (raw) => {
      const data = JSON.parse(raw);
      console.log(data);
      if (!data.jobList) {
        return {
          ...data,
          jobList: [],
        };
      }
      const jobList = data.jobList.map((v) => ({
        ...v,
        jobDate: moment(v.jobDate, "DD/M/YYYY").format("DD/MM/YYYY"),
        jobStatusDesc: authenUser.lang === "th" ? v.jobStatusTh : v.jobStatusEn,
        jobStatusDict: { th: v.jobStatusTh, en: v.jobStatusEn },
        rating: +v.raiting,
      }));
      return {
        ...data,
        jobList,
      };
    },
  ];
  return apix.get(`/job?${query}`, {
    cancelToken: source.token,
    transformResponse,
  });
};

// axios(
//   {
//     method: 'get',
//     url: `https://release.skootar.com/skootar_api_dev/api/v2/job?${query}`,
//     auth: {
//       username: 'autthapon@gmail.com',
//       password: 'notenote',
//     },
//   },
//   transformResponse
// );
// };

export const getJobDetail = (jobId, lang = activeLang()) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    lang,
    jobId,
  };
  return apix.post("/get_job_detail", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        const data = resp.jobDetail;
        if (!!data) {
          const transformData = {
            responseCode: +resp.responseCode,
            responseDesc: resp.responseDesc,
            jobDetail: {
              ...data,
              jobDate: moment(data.jobDate, "DD/M/YYYY").format("DD/MM/YYYY"),
              rating: +data.raiting,
              jobType: +data.jobType,
              jobOptions: !!data.option
                ? data.option.split(",").map((v) => parseInt(v, 10))
                : [],
              jobStatusDesc:
                lang === "th" ? data.jobStatusTh : data.jobStatusEn,
              jobStatusDict: { th: data.jobStatusTh, en: data.jobStatusEn },
            },
          };
          return transformData;
        }
        return resp;
      },
    ],
  });
};

export const updateUser = (data) => {
  const authenUser = getAuthenUser();
  const user = utils.getContact();
  const req = {
    ...authenUser,
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNo: user.phoneNo,
    userType: 2,
  };

  if (data.signUpType === 1) {
    // type company inject data
    req.companyName = data.companyName;
    req.companyTaxId = data.companyTaxId;
    req.companyAddress = data.companyAddress;
    req.userType = 1;
    // new add company
    req.company = {
      address: data.companyAddress,
      companyName: data.companyName,
      taxNo: data.companyTaxId,
      companyId: data.companyId ? data.companyId : 0,
      isActive: true,
    };
  }
  return apix.post("/update_user?welcome=1", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        if (+resp.responseCode === 200) {
          const updateData = {
            ...user,
            ...data,
            userType: req.userType,
          };
          delete updateData.signUpType;
          delete updateData.companyId;
          delete updateData.email;
          delete updateData.phone;
          utils.updateContact(updateData);
        }
        return {
          ...resp,
          responseCode: +resp.responseCode,
        };
      },
    ],
  });
};

export const updateUserSetting = (data) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    ...data,
  };
  if (!!req.phoneNo) {
    req.phoneNo = utils.validateMobile(req.phoneNo)
      ? utils.rmSpecialChar(req.phoneNo)
      : req.phoneNo;
  }
  delete req.password;
  delete req.skToken;
  return apix.post("/update_user", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        console.log(resp);
        return {
          ...resp,
          responseCode: +resp.responseCode,
        };
      },
    ],
  });
};

export const showInvoiceOverdue = () =>
  apix.get("/v2/invoice/overdue", {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });

export const getInvoiceNotPay = () => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
  };
  return apix.post("/invoice_not_pay", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};
export const updatePassword = (data) => {
  const user = utils.getContact();
  const req = {
    username: user.userName,
    password: data.password,
    newPassword: data.newPassword,
    lang: activeLang(),
  };
  return apix.post("/change_password", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const getInvoicePaid = (date) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    ...date,
    // userName: 'tttttest7@gmail.com',
    // password: '12345pam',
  };
  return apix.post("/invoice_paid", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const listJobWaitingForRating = () => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
  };
  return apix.post("/job_waiting_for_rating", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const rateJob = (jobId, rating, comment) => {
  const req = {
    jobId,
    rating,
    ratingComment: comment,
  };
  return apix.post("/job_rating", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        const transformData = {
          ...resp,
          responseCode: +resp.responseCode,
          responseDesc: resp.responseDesc,
        };
        return transformData;
      },
    ],
  });
};

export const rateJobToken = (tokenRating, rating, comment) => {
  const req = {
    tokenRating,
    rating,
    ratingComment: comment,
  };
  return apix.post("/job_rating_token", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        const transformData = {
          ...resp,
          responseCode: +resp.responseCode,
          responseDesc: resp.responseDesc,
        };
        return transformData;
      },
    ],
  });
};

export const addFavDriver = (skootarId) => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
    lang: activeLang(),
    channel: getChannel(),
    remark: "",
    skootarId,
  };
  return apix.post("/add_favorite_driver", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        const transformData = {
          ...resp,
          responseCode: +resp.responseCode,
          responseDesc: resp.responseDesc,
        };
        return transformData;
      },
    ],
  });
};

export const addBlockDriver = (skootarId) => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
    lang: activeLang(),
    channel: getChannel(),
    remark: "",
    skootarId,
  };
  return apix.post("/add_block_driver", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const resp = JSON.parse(raw);
        const transformData = {
          ...resp,
          responseCode: +resp.responseCode,
          responseDesc: resp.responseDesc,
        };
        return transformData;
      },
    ],
  });
};

export const getInvoiceView = ({ invoiceId }) => {
  const req = {
    invoiceId,
  };
  return apix.post("/get_invoice_view", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const getDriverFavorite = () => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
  };
  return apix.post("/get_list_favorite_driver", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const postponeJob = (data) => {
  const userInfo = utils.getContact();
  const req = {
    username: userInfo.userName,
    facebookId: !!data.facebookId ? data.facebookId : "",
    facebookTokenId: !!data.facebookTokenId ? data.facebookTokenId : "",
    jobId: data.jobId,
  };
  return apix.post(`/v3/job/postpone`, JSON.stringify(req), {
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};
export const cancelFixDriverJob = (data) =>
  apix.post(`/job/${data.jobId}/fixed/cancel`, {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });

export const getNews = () => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
  };
  const qStr = !!req.userId ? `&user_id=${req.userId}` : "";
  return apix.get(`/v2/news?news_type=news${qStr}`, JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
        };
        return result;
      },
    ],
  });
};

export const getBadgeNews = () => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
  };
  const qStr = !!req.userId ? `?user_id=${req.userId}` : "";
  return new Promise((resolve, reject) => {
    apix
      .get(`/v2/news/unread/count${qStr}`, JSON.stringify(req), {
        cancelToken: source.token,
      })
      .then((result) => {
        console.log("result", result);
        if (result.status === 200 && result.data.responseCode === 200) {
          resolve(result.data.result.count);
        } else {
          resolve(0);
        }
      })
      .catch((error) => {
        console.log(error);
        resolve(0);
      });
  });
};

export const getPromotion = () => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
  };
  const qStr = !!req.userId ? `&user_id=${req.userId}` : "";
  return apix.get(`/v2/news?news_type=promotion${qStr}`, JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
        };
        return result;
      },
    ],
  });
};

export const readNewsPromotions = (id) => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
  };
  return apix.get(
    `/v2/news/${id}?user_id=${req.userId && req.userId}`,
    JSON.stringify(req),
    {
      cancelToken: source.token,
      transformResponse: [
        (raw) => {
          const data = JSON.parse(raw);
          const result = {
            ...data,
          };
          return result;
        },
      ],
    }
  );
};
export const getNewsPromotions = () => Promise.all([getNews(), getPromotion()]);
// apix.post('/get_customer_news', {
//   cancelToken: source.token,
//   transformResponse: [raw => JSON.parse(raw)],
// });

export const getMyLocationList = () => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    all: true,
  };
  return apix.post("/list_location", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};
export const saveLocation = (location) => {
  const authenUser = getAuthenUser();
  const form = {
    ...location,
    ...authenUser,
  };
  console.log(form);
  return apix.post("/save_location", JSON.stringify(form), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const removeLocation = (addressId) => {
  const authenUser = getAuthenUser();
  const form = {
    ...authenUser,
    addressId,
  };
  return apix.post("/remove_location", JSON.stringify(form), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};
export const getMyReceiptLocationList = () => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
  };
  return apix.get("/invoice/header", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

export const createReceiptLocation = (location) => {
  return apix.post(
    "/invoice/header",
    JSON.stringify({ ...location, lang: activeLang() }),
    {
      cancelToken: source.token,
      transformResponse: [(raw) => JSON.parse(raw)],
    }
  );
};
export const saveReceiptLocation = (location) => {
  return apix.post(
    "/invoice/header",
    JSON.stringify({ ...location, lang: activeLang() }),
    {
      cancelToken: source.token,
      transformResponse: [(raw) => JSON.parse(raw)],
    }
  );
};

export const removeReceiptLocation = (location) => {
  return apix.post(
    `/invoice/header/delete/${location.id}?lang=${activeLang()}`,
    null,
    {
      cancelToken: source.token,
      transformResponse: [(raw) => JSON.parse(raw)],
    }
  );
};

export const removeDriverFavorite = (skootarId) => {
  const user = utils.getContact();
  const req = {
    userId: user.userId,
    skootarId,
  };
  return apix.post("/remove_favorite_driver", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const fixDriverFavorite = (fixDriver) => {
  // api_update_fix_driver
  const user = utils.getContact();
  const req = {
    phoneNo: user.phoneNo,
    fixDriver,
  };
  return apix.post("/api_update_fix_driver", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const omiseCreateCustomer = () => {
  const user = utils.getContact();
  const description = `${user.userName} ${user.firstName} ${user.lastName}`;
  const req = {
    channel: getChannel(),
    email: user.userName,
    description,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_create_customer", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
          customerId: data.customerId,
        };
        return result;
      },
    ],
  });
};

export const omiseUpdateCustomer = () => {
  const user = utils.getContact();
  const req = {
    channel: getChannel(),
    customerId: user.omiseCustomerId,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_update_customer", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const omiseListCard = () => {
  const user = utils.getContact();
  const req = {
    channel: getChannel(),
    customerId: user.omiseCustomerId,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_list_cards", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};
export const omiseAddCardToCustomer = (cardTokenId) => {
  const user = utils.getContact();
  const req = {
    customerId: user.omiseCustomerId,
    cardTokenId,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_add_card_to_customer", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};
export const omiseUpdateCard = (cardId, holderName, expMonth, expYear) => {
  const user = utils.getContact();
  const req = {
    channel: getChannel(),
    customerId: user.omiseCustomerId,
    cardId,
    holderName,
    mmExp: expMonth,
    yyyyExp: expYear,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_update_card", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};
export const omiseDeleteCard = (cardId) => {
  const user = utils.getContact();
  const req = {
    channel: getChannel(),
    customerId: user.omiseCustomerId,
    cardId,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_delete_card", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};
export const omiseSetDefaultCard = (cardId) => {
  const user = utils.getContact();
  const req = {
    channel: getChannel(),
    customerId: user.omiseCustomerId,
    cardId,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_set_default_card", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const omiseChargeInvoice = (invoiceId, wht = 0) => {
  const user = utils.getContact();
  const req = {
    channel: getChannel(),
    userName: user.userName,
    invoiceId,
    wht,
    lang: activeLang(),
  };
  return apix.post("/cc_omise_charge_invoice", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};
export const omiseListInternetBanking = () =>
  apix.get("/cc_omise_list_internetbanking", {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
export const omiseListCouterService = () =>
  apix.get("/cc_omise_list_bill_payment", {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
export const omisePayInvoiceViaInternetBanking = (invoiceId, bank, wht = 0) => {
  const user = utils.getContact();
  const req = {
    invoiceId,
    bank,
    omiseCustomerId: user.omiseCustomerId,
    wht,
    lang: activeLang(),
  };
  return apix.post(
    "/cc_omise_pay_invoice_via_internetbanking",
    JSON.stringify(req),
    {
      cancelToken: source.token,
      transformResponse: [
        (raw) => {
          const data = JSON.parse(raw);
          const result = {
            ...data,
            responseCode: +data.responseCode,
            responseDesc: data.responseDesc,
            bankKey: bank,
          };
          return result;
        },
      ],
    }
  );
};
export const omisePayInvoiceViaCounterService = (
  invoiceId,
  couterSource,
  wht = 0
) => {
  const user = utils.getContact();
  const req = {
    invoiceId,
    source: couterSource,
    omiseCustomerId: user.omiseCustomerId,
    wht,
    lang: activeLang(),
  };
  return apix.post(
    "/cc_omise_pay_invoice_via_bill_payment",
    JSON.stringify(req),
    {
      cancelToken: source.token,
      transformResponse: [
        (raw) => {
          const data = JSON.parse(raw);
          const result = {
            ...data,
            responseCode: +data.responseCode,
            responseDesc: data.responseDesc,
          };
          return result;
        },
      ],
    }
  );
};

export const payWithOmiseCreditCard = (invoiceId, wht = 0) => {
  const user = utils.getContact();
  const req = {
    userName: user.userName,
    invoiceId,
    wht,
    currency: "",
    lang: activeLang(),
  };
  return apix.post("/cc_omise_charge_invoice", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const customerNeedHelp = (jobId, message) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    jobId,
    message,
  };
  return apix.post("/customer_need_help", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const cancelJob = (jobId, cancelReason) => {
  const authenUser = getAuthenUser();
  const req = {
    ...authenUser,
    jobId,
    cancelReason,
  };
  return apix.post("/cancel_job", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          responseCode: +data.responseCode,
          responseDesc: data.responseDesc,
        };
        return result;
      },
    ],
  });
};

export const promotionWeb = () =>
  apix.get("/promotion/web", {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        const result = {
          ...data,
          respCode: +data.respCode,
        };
        return result;
      },
    ],
  });

export const getNearDrivers = (lat, lng, vehicleType) =>
  apix.get(`/v2/get_near_drivers/${lat}/${lng}?vehicle_type=${vehicleType}`, {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });

export const walletPackagesPay = (pack) => {
  const req = {
    token: pack.token,
  };
  return apix.post("/wallet/packages/pay", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });
};

export const walletPackages = () =>
  apix.get("/wallet/packages", {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });

export const walletInfo = () =>
  apix.get("/wallet/info", {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });

export const getSuggestMessage = (userId) => {
  return apix.get(`/order/init/${userId}`, {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });
};

export const walletHistory = ({
  fromDate,
  toDate,
  limit = 20,
  page = 0,
  // sortBy = "log.prepaid_id desc,log.job_id desc",
  sortBy = "log.created_datetime desc",
}) => {
  const user = utils.getContact();
  const req = {
    userName: user.userName,
    lang: activeLang(),
    fromDate,
    toDate,
    limit,
    sortBy,
    page,
  };
  return apix.post("/wallet/history", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });
};

export const walletRedeem = ({ code, directMoney }) => {
  const user = utils.getContact();
  const req = {
    userName: user.userName,
    lang: activeLang(),
    code,
    directMoney,
  };
  return apix.post("/wallet/redeem ", JSON.stringify(req), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });
};

export const optimizeRoute = (routes) =>
  apix.post("/optimize", JSON.stringify(routes), {
    cancelToken: source.token,
    transformResponse: [
      (raw) => {
        const data = JSON.parse(raw);
        return data;
      },
    ],
  });

export const signon = ({ partnerId, ...data }) => {
  const body = JSON.stringify({
    partnerId,
    ...data,
  });
  return apix
    .post("/signon", body, {
      cancelToken: source.token,
    })
    .then((resp) => {
      if (resp.data && resp.data.responseCode === "200") {
        const { responseDesc, userInfo: u } = resp.data;
        const objUser = {
          userName: u.userName,
          userType: u.userType,
          userStatus: u.userStatus,
          phoneNo: u.phoneNo,
          firstName: u.firstName,
          lastName: u.lastName,
          skToken: responseDesc,
        };
        window.localStorage.setItem("objUser", cryptJson(objUser));
        utils.setChannel("LivingOS");
      } else {
        if (resp.data) {
          throw new Error(`Singon Failed: ${resp.data.responseDesc}!`);
        } else {
          throw new Error("Singon Failed: No Error Message!");
        }
      }
      return resp;
    });
};

export const getBusinessPromotions = () => {
  return apix.post("/user_tier/promotions", {});
};

export const getUserTier = () => {
  return apix.post("/user_tier/", {});
};

export const slipsUpload = ({
  imgBase64,
  jobId,
  skootarId,
  channel = "web",
}) => {
  return apix.post(
    "/slip/upload",
    {
      imgBase64,
      jobId,
      skootarId,
      channel,
    },
    { cancelToken: source.token }
  );
};

export const slipsRemove = ({ fileName, jobId, channel = "web" }) => {
  return apix.post(
    "/slip/remove",
    {
      fileName,
      jobId,
      channel,
    },
    { cancelToken: source.token }
  );
};

export const getDepartmentUsers = () => {
  return apix.get("/v2/department/users", {
    cancelToken: source.token,
    transformResponse: [(raw) => JSON.parse(raw)],
  });
};

// https://dev.skootar.com/api/optimize/

// =================== End Need authentication checking ===================
