
import { defaultJobListState } from './../reducers/dashboardReducer';

export const setJobTableListState = (state = defaultJobListState) => ({
  type: 'SET_DASHBOARD_JOBLIST_STATE',
  state,
});

export const setExpandData = (state) => ({
  type: 'SET_EXPAND_DATA',
  state,
});

export const forceListAllJobs = (state) => ({
  type: 'FORCE_LIST_ALL_JOBS',
  state,
});

export const setFirebaseJobSet = (state) => ({
  type: 'SET_FIREBASE_JOB_SET',
  state,
});

export const removeAllDashboardData = () => ({
  type: 'REMOVE_ALL_DASHBOARD_DATA',
});
