const config = require('./../helper/config.js');

export default class Facebook {
  init() {
    return new Promise(resolve => {
      const { options } = config.facebook;
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: options.appId,
          cookie: true,
          version: options.version,
          xfbml: true,
          autoLogAppEvents: true,
          status: true,
          oauth: true,
        });
        window.FB.Canvas.setAutoGrow();
        resolve(window.FB);
      };
      const fjs = document.getElementsByTagName('script')[0];
      if (document.getElementById('facebook-jssdk')) {
        return;
      }

      const js = document.createElement('script');
      js.id = 'facebook-jssdk';
      js.async = true;
      js.src = `//${options.domain}/${options.language}/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    });
  }
}
