import { cryptJson, decryptJson } from "./../api/crypt";
import { setStorageSettings, setStorageObj } from "./localStorage";

import moment from "moment";
import util from "util";
import numeral from "numeral";
import LZString from "lz-string";
import Platform from "platform";
import CryptoJS from "crypto-js";

export const convertDMSToDD = (dms) => {
  const parts = dms.split(/[^\d+(\,\d+)\d+(\.\d+)?\w]+/);
  const degrees = parseFloat(parts[0]);
  const minutes = parseFloat(parts[1]);
  const seconds = parseFloat(parts[2].replace(",", "."));
  const direction = parts[3];

  let dd = degrees + minutes / 60 + seconds / (60 * 60);

  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
};

export const cLog = (text) => {
  console.log(`%c${text}`, "background: green;color: white;");
};

export const validateLatLng = (text) => {
  let result = null;
  const hasDMS = text.split("°").length === 3; // coordinates
  const hasLngLat =
    text.split(",").length === 2 &&
    // text.split(',')[0].substring(0, 3) === '13.';
    /(12|13|14)/.test(text.split(",")[0].substring(0, 3));
  if (hasDMS) {
    const charSplit = text.substr(text.indexOf("N") + 1, 1);
    const derictions = text.split(charSplit);
    if (derictions.length === 2) {
      result = {
        lat: convertDMSToDD(derictions[0]),
        lng: convertDMSToDD(derictions[1]),
        dms: true,
      };
    }
  } else if (hasLngLat) {
    const derictions = text.split(",");
    result = { lat: derictions[0], lng: derictions[1], dms: false };
  }
  return result;
};

export const getDateFromOffset = (offset, date = new Date()) => {
  const offsetDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  offsetDay.setDate(date.getDate() + offset);
  return offsetDay;
};

export const validateMobileNumber = (number) => {
  const regex01 = /^\+[6]{2}[-\s]?0?[689]\d[-\s]?\d{3}[-\s]?\d{4}$/;
  const regex02 = /^0[689]\d[-\s]?\d{3}[-\s]?\d{4}$/;
  return regex01.test(number) || regex02.test(number);
};

const appender = (number, separator = "-") => {
  const regex =
    /^(\+66([-\s]{1}0?[689]{1}\d{1})?|0[689]{1}\d{1})([^-\s]{1})?([^-\s]{1})?([-\s]{1}\d{3})?([^-\s]{1})?$/;
  const m = regex.exec(number);
  return m !== null ? separator : "";
};
const appender2 = (number, region) => {
  const separator = "-";
  const regexCapital =
    /^(\+66([-\s]{1}0?[2]{1})?|0[2]{1})([^-\s]{1})?([^-\s]{1})?([-\s]{1}\d{3})?([^-\s]{1})?$/;
  const regexOther =
    /^(\+66([-\s]{1}0?[3457]{1}\d{1})?|0[3457]{1}\d{1})([^-\s]{1})?([^-\s]{1})?([-\s]{1}\d{3})?([^-\s]{1})?$/;
  const regexPhone = region === 1 ? regexCapital : regexOther;
  const m = regexPhone.exec(number);
  return m !== null ? separator : "";
};

export const appendSeparatorMobile = (number, separator = "-") => {
  const newNumber = `${number}${appender(number, separator)}`;
  return newNumber;
};

export const getBrowserVersion = () => {
  let browser = {
    deviceModel: "",
    osVersion: "",
    appVersion: "",
  };
  try {
    if (Platform) {
      browser = {
        deviceModel: `${Platform.os.family} ${Platform.os.version}`,
        osVersion: Platform.name,
        appVersion: Platform.version,
      };
    }
  } catch (e) {
    // Nothing
  }
  return browser;
};

export const validateMobile = (number) => {
  const regexMobile =
    /^(\+66[-\s]?0?|0)[689]{1}[0-9]{1}[-\s]?\d{3}[-\s]?\d{4}$/;
  const str = number;
  const m = regexMobile.exec(str);
  let result;
  if (m !== null) {
    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      if (groupIndex === 0) {
        const noCodeNumber = match.replace(/\+66[-\s]?0?/, 0);
        const pureNumber = noCodeNumber.replace(/\+66[-\s]|\D/g, "");
        const arrNumber = [...pureNumber];
        result = arrNumber.reduce((total, value) => {
          const check = `${total}${value}`;
          return `${check}${appender(check)}`;
        });
      }
    });
  }
  return result;
};
export const validateHomePhone = (number, region) => {
  const regexCapital = /^(\+66[-\s]?0?|0)[2]{1}[-\s]?\d{3}[-\s]?\d{4}$/;
  const regexOther =
    /^(\+66[-\s]?0?|0)[3457]{1}[0-9]{1}[-\s]?\d{3}[-\s]?\d{3}$/;
  const regexPhone = region === 1 ? regexCapital : regexOther;
  const str = number;
  const m = regexPhone.exec(str);
  let result;
  if (m !== null) {
    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      if (groupIndex === 0) {
        const noCodeNumber = match.replace(/\+66[-\s]?0?/, 0);
        const pureNumber = noCodeNumber.replace(/\+66[-\s]|\D/g, "");
        const arrNumber = [...pureNumber];
        result = arrNumber.reduce((total, value) => {
          const check = `${total}${value}`;
          return `${check}${appender2(check, region)}`;
        });
      }
    });
  }
  return result;
};

export const validateContactPhone = (number) => {
  try {
    const regexMobile =
      /^(\+66\d{9}|^\+66\d{1}-\d{4}-\d{4})|^(\+66[-\s]?0?|0)[689]{1}[0-9]{1}[-\s]?\d{3}[-\s]?\d{4}|^(06|08|09)\d{8}$/;

    const regexPhone = /((\+66|0)[689]\d{1}\-?\d{3}\-?\d{4}|(\+66|0)[1-57]\d{1}\-?\d{3}\-?\d{3,4})/gm

    const str = number.replace(/(\s|-)|(\)|\()/g, "");
    const m = regexPhone.exec(str);
    let result = false;
    if (m !== null) {
      result = true;
    }
    return result;
  } catch (error) {
    console.error(error);
    return false;
  }
};


export const validateConfirmPhone = (number) => {
  try {
    const regexMobile =
      /(^0[689]{1}[0-9]{1}[-\s]?\d{3}[-\s]?\d{4})|(^0[2]{1}[-\s]?\d{3}[-\s]?\d{4})|(^0[3457]{1}[0-9]{1}[-\s]?\d{3}[-\s]?\d{3})/;
    const str = number.replace(/(\s|-)|(\)|\()/g, "");
    const m = regexMobile.exec(str);
    let result = false;
    if (m !== null) {
      result = true;
    }
    return result;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const filterInputPhone = (text) => {
  const regex = /^([+\-0-9]+)?$/;
  const m = regex.exec(text);
  return m !== null;
};

export const filterInputNumber = (text) => {
  const regex = /^(\d+)?$/;
  const m = regex.exec(text);
  return m !== null;
};

export const filterInputTaxid = (text) => /^[0-9]{0,13}$/.test(text);

export const unmaskTaxid = (text = "") => {
  const match = !!text ? text.match(/\d+[^\s\S]?/g) : false;
  if (match) {
    return match.reduce((total, value) => `${total}${value}`);
  }
  return text;
};

export const maskTaxid = (text = "") => {
  const umask = unmaskTaxid(text);
  const match = !!umask
    ? umask.match(/(\d{1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/)
    : false;
  if (match) {
    const fltMatch = match.filter((v, i) => i > 0 && v);
    return fltMatch.reduce((total, value, i) => `${total}-${value}`);
  }
  return text;
};

export const unmaskRedeemCode = (text = "") => {
  const match = !!text
    ? text.match(/^(SW|sw)([a-zA-Z0-9]{3})[-]?([a-zA-Z0-9]{4})$/)
    : false;
  if (match) {
    return `${match[1]}${match[2]}${match[3]}`;
  }
  return null;
};

export const validatePromoCode = (code) =>
  // /^[a-zA-Z0-9]+$/.test(code) || code.length === 0;
  /[a-zA-Z0-9]/.test(code) || code.length === 0;

export const isSameObject = (obj1, obj2) => {
  // const strObj1 = !!obj1 ? JSON.stringify(obj1) : undefined;
  // const strObj2 = !!obj2 ? JSON.stringify(obj2) : undefined;
  const strObj1 = !!obj1 ? util.inspect(obj1) : undefined;
  const strObj2 = !!obj2 ? util.inspect(obj2) : undefined;
  return strObj1 === strObj2;
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\\']+(\.[^<>()[\]\\.,;:\s@\\']+)*)|(\\'.+\\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validateLengthPassword = (pass) => /^\w{6,15}$/.test(pass);

export const validateEqual = (txt1, txt2) => txt1 === txt2;

export const validateTime = (time) =>
  /^([0-1]?[0-9]|2[0-3])(:[0-5][0-9])?$/.test(time);

export const priceFormat = (n) => {
  try {
    return numeral(n).format("0,0.[00]");
  } catch (error) {
    return "";
  }
};

export const numberFormat = (n) => {
  try {
    return (Math.floor(n * 100) / 100).toFixed(2);
  } catch (error) {
    return 0;
  }
  // const parts = (+n).toFixed(2).split(".");
  // const num = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${parts[1]}`;
  // return num;
};

export const validateCardNo = (card) => {
  const re = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  const obj = {
    card: false,
  };

  Object.keys(re).map((key) => {
    if (re[key].test(card)) {
      obj.card = true;
      obj.type = key;
    }
  });
  return obj;
};

export const validateCardExpireDate = (date) =>
  /(1[0-2]|0[1-9])\/\d\d$/.test(date.replace(/\s+/g, ""));

export const validateHolderName = (holderName) =>
  /^([a-zA-Z]+(-|\s)?)+$/.test(holderName);

export const validateCCV = (ccv) => /^[0-9]{3,4}$/.test(ccv);

export const validateTaxid = (taxid) => {
  const umask = unmaskTaxid(taxid);
  const validLen = /^[0-9]{13}$/.test(umask);
  let validCheckSum = false;
  if (validLen) {
    const numbers = umask.match(/\d/g);
    const sum1To12 = numbers
      .filter((n, i) => i < numbers.length - 1)
      .map((n, i) => n * (14 - i - 1))
      .reduce((total, val) => total + val);
    const x = sum1To12 % 11;
    const N13 = x > 1 ? 11 - x : 1 - x;
    validCheckSum = +numbers[numbers.length - 1] === N13;
    // console.warn({ validCheckSum, x, N13, taxid });
  }
  return validLen && validCheckSum;
};

export const rmSpecialChar = (txt) => txt.replace(/\W+/g, "");

export const checkIsToken = () => {
  try {
    decryptJson(window.localStorage.getItem("objUser"));
    return true;
  } catch (error) {
    return false;
  }
};

export const isLimitRatingPerDay = () => {
  try {
    const key = "ratingLimitExpiry";
    const expiryDate = window.localStorage.getItem(key);
    console.log("isLimitRatingPerDay", {
      expiryDate: +expiryDate,
      now: moment().valueOf(),
    });
    if (!expiryDate || +expiryDate < moment().valueOf()) {
      const nextExpiry = moment().endOf("day").valueOf();
      window.localStorage.setItem(key, nextExpiry);
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const removeLimitRatingPerDay = () => {
  const key = "ratingLimitExpiry";
  window.localStorage.removeItem(key);
};

export const signOut = () => {
  if (!!window.FB) {
    window.FB.logout((response) => {
      console.log(response);
    });
  }
  // setStorageSettings({ proMotion: true });

  return new Promise((s) => {
    removeChannel();
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("objUser");
    window.localStorage.removeItem("cryptSignup");
    // localStorage.removeItem('onBoarding'); neworder
    removeLimitRatingPerDay();
    s(true);
  });
};

export const checkDateStartToEnd = (startDate, expiredDate) => {
  const start = moment(startDate, "DD/MM/YYYY");
  const expired = moment(expiredDate, "DD/MM/YYYY");
  const now = moment(moment(new Date()), "DD/MM/YYYY");
  const diffStart = now.diff(start);
  const diffExpired = now.diff(expired);
  const open = diffStart >= 0 && diffExpired < 0;
  return open;
};

export const getSecondsDiffFromDate = (startDate, endDate) => {
  const result = moment
    .duration(moment(startDate).diff(moment(endDate)))
    .asMinutes();
  return Math.round(result * 60);
};

export const chipTimeStepMin = (date, addMinutes) => {
  const d = moment(date, "YYYY-MM-DD HH:mm:ss")
    .set({ second: 0, millisecond: 0 })
    .add("Minutes", addMinutes);
  return d;
};

export const getContact = () => {
  try {
    return decryptJson(window.localStorage.getItem("objUser"));
  } catch (error) {
    return false;
  }
};

export const popKeyDirectionCaching = (direction) => {
  const o = Object.assign({}, direction);
  delete o[Object.keys(o)[0]];
  return o;
};

export const getKeyDirectionCaching = () => {
  try {
    return window.localStorage.getItem("dc") === null
      ? {}
      : JSON.parse(LZString.decompress(window.localStorage.getItem("dc")));
  } catch (error) {
    return {};
  }
};

export const setKeyDirectionCaching = (direction) => {
  try {
    return window.localStorage.setItem(
      "dc",
      LZString.compress(JSON.stringify(direction))
    );
  } catch (error) {
    return false;
  }
};

export const saveKeyDirectionCaching = (direction) => {
  try {
    const dc = getKeyDirectionCaching();
    const limit = 250;
    let d;
    const enCode = JSON.stringify(direction.point);
    const key = {};
    key[enCode] = direction.overviewPolyline;
    if (Object.keys(dc).length < limit) {
      d = Object.assign(dc, key);
    } else {
      const popD = popKeyDirectionCaching(dc);
      d = Object.assign(popD, key);
    }
    console.log(Object.keys(d).length, d);
    setKeyDirectionCaching(d);
    return direction.overviewPolyline;
  } catch (e) {
    return false;
  }
};
export const findKeyDirection = (markers) => {
  const list = markers.map((item, key) => ({ lat: item.lat, lng: item.lng }));
  const keyDc = getKeyDirectionCaching();
  return !!keyDc[JSON.stringify(list)]
    ? String.raw`${keyDc[JSON.stringify(list)]}`
    : false;
};

export const updateContact = (obj) => {
  try {
    const usr = obj;
    delete usr.password;
    window.localStorage.setItem("objUser", cryptJson(usr));
    return decryptJson(window.localStorage.getItem("objUser"));
  } catch (error) {
    return false;
  }
};

export const setChannel = (channel = "web") => {
  try {
    window.localStorage.setItem("channel", channel);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getChannel = () => {
  try {
    return window.localStorage.getItem("channel");
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const removeChannel = () => {
  try {
    window.localStorage.removeItem("channel");
  } catch (error) {
    console.error(error);
  }
};

// export const parseQueryStringToJson = (search = "") => {
//   return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
// };

export const encryptASE256 = (messageToencrypt = "", secretkey = "") => {
  const encryptedMessage = CryptoJS.AES.encrypt(messageToencrypt, secretkey);
  return encryptedMessage.toString();
};

export const decryptASE256 = (encryptedMessage = "", secretkey = "") => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, secretkey);
  const decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);
  // const data = [{ id: 1 }, { id: 2 }];
  // // Encrypt
  // const ciphertext = CryptoJS.AES.encrypt(
  //   JSON.stringify(data),
  //   "secret key 123"
  // ).toString();
  // // Decrypt
  // const bytes = CryptoJS.AES.decrypt("U2FsdGVkX1+LrmD5vD4PE3+OX6C/t1SeeL5TtQAW5wU8q79oOZDg7ALdpg0r/AP1z6uxSttUdViPP19Y3+CLmPg3vP9mpXqdFh4cXU5KKpVh+iqhtIIL/UQiLyXvV16n", secretkey);
  // const c =
  //   "U2FsdGVkX1+LrmD5vD4PE3+OX6C/t1SeeL5TtQAW5wU8q79oOZDg7ALdpg0r/AP1z6uxSttUdViPP19Y3+CLmPg3vP9mpXqdFh4cXU5KKpVh+iqhtIIL/UQiLyXvV16n";
  // console.log("test ", encryptedMessage === c);
  // console.log(c);
  // console.log(encryptedMessage);
  // console.log(typeof c);
  // console.log(typeof encryptedMessage);
  // const t1 = CryptoJS.AES.decrypt(c, secretkey);
  // const t2 = CryptoJS.AES.decrypt(encryptedMessage, secretkey);
  // console.log("t1 ", t1);
  // console.log("t2 ", t2);
  // console.log("t1 ", t1.toString(CryptoJS.enc.Utf8));
  // console.log("t2 ", t2.toString(CryptoJS.enc.Utf8));
  // const bytes = CryptoJS.AES.decrypt(`${encryptedMessage}`, secretkey);
  // const decryptedMessage = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedMessage;
};

export const replaceNameAddress = (address, addressName) => {
  try {
    const res = address.includes(addressName)
      ? address
      : `${address} ${addressName}`;
    return res;
  } catch (error) {
    return address;
  }
};

export const saveKlook = (obj) => {
  try {
    window.localStorage.setItem("kl", cryptJson(obj));
    return decryptJson(window.localStorage.getItem("kl"));
  } catch (error) {
    return false;
  }
};

export const getKlook = () => {
  try {
    return decryptJson(window.localStorage.getItem("kl"));
  } catch (error) {
    return false;
  }
};

export const getDataSignUp = () => {
  try {
    if (!!window.localStorage.getItem("cryptSignup")) {
      return decryptJson(window.localStorage.getItem("cryptSignup"));
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getReferCode = () => {
  if (!!window.localStorage.getItem("objUser")) {
    return decryptJson(window.localStorage.getItem("objUser")).referCode;
  }
  return "";
};

export const formatDate = (date, formatString = "YYYY-MM-DD") => {
  console.log("Test formatDate", date);
  if (!!date && util.isDate(date)) {
    const dateStr = moment(date).format(formatString);
    console.log("Test formatDate", dateStr);
    return dateStr;
  }
  return undefined;
};

export const imageUrlToB64Url = (urlImg) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);
      resolve(canvas.toDataURL());
    };
    image.onerror = (elm, err) => {
      reject(err);
    };
    image.src = urlImg;
  });

export const resizeImage = (settings) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");
  const resize = () => {
    let width = image.width;
    let height = image.height;
    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL(settings.file.type);
    return dataUrl;
  };
  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }
    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const isEmptyString = (value) => /\S/g.test(value) && !!value;

export const removeSpaceString = (value) => {
  const strReg = /[^" "].*/g.exec(value);
  return strReg !== null ? strReg[0] : "";
};

export const getParams = (search) =>
  search
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

export const getRangeYear = () =>
  Array.from(Array(moment().format("YYYY") - 2014).keys()).map((d) => 2015 + d);

export const otpCode = (otp) => /^[0-9]{4}$/g.test(otp);

export const removeEmoji = (text) => {
  return text === "" || text === null
    ? ""
    : text
        .match(/[ก-๙!-~\s]/gi)
        .join("")
        .replace(/  +/g, " ");
};

export const removeNewLine = (text, replace = "") =>
  text.replace(/(?:\r\n|\r|\n)/g, replace);

export const userTier = {
  NORMAL: "normal",
  SILVER: "silver",
  GOLD: "gold",
  PLATINUM: "platinum",
  CONTRACT: "contract",
};

export const mode = {
  EDIT: "edit",
  ADD: "add",
  VIEW: "view",
};

export const formatJobIdWithDash = (jobId) => {
  const regex = /^(?!J).*/;

  if (typeof jobId !== "string" || jobId.length <= 5 || regex.test(jobId)) return jobId;
  return jobId.slice(0, -5) + "-" + jobId.slice(-5);
};
