import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

const CircularProgressLoader = ({ label = 'Loading...', style }) => (
  <div style={{ height: '100%', position: 'relative', minHeight: 100 }}>
    <div
      style={{
        top: 'calc(50% - 30px)',
        height: '60px',
        width: '100%',
        position: 'absolute',
      }}
    >
      <CircularProgress
        size={30}
        style={{ left: 'calc(50% - 15px)', ...style }}
      />
      <div style={{ textAlign: 'center' }}>{label}</div>
    </div>
  </div>
);

export default CircularProgressLoader;
