const getDataLayer = () => dataLayer; // eslint-disable-line no-undef

export const send = (eventName, data = {}) => {
  const dataLayer = getDataLayer();

  const eventData = {
    event: eventName,
    ...data,
  };

  dataLayer.push(eventData);
};

export const converterEstimatePrice = es => ({
  normal_price: es.normal,
  net_price: es.total,
  discount: es.discount,
  free_credit: es.freeCredit,
  distance: es.distance,
  promo_code: es.promoCode,
});

export const converterDeliveryType = dt => ({
  delivery_type:
    dt === 1 ? 'Document' : dt === 2 ? 'Parcel' : dt === 3 ? 'Food' : '',
});

export const converterOrderType = ot => ({
  order_type: ot === 0 || ot === 'now' ? 'Now' : 'Schedule',
});
