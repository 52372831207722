import { setLang, updateTobar } from "./reduxUtils";
import { checkIsToken, getContact } from "./utils";

const generateUUID = () => {
  let d = new Date().getTime();
  if (Date.now) {
    d = Date.now(); // high-precision timer
  }
  const uuid = "Sxxx-xxxK-yxxx-xxxy-yxxx-xxxy-xxxy".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const guid = () => {
  const localGuid = !!window.localStorage.getItem("guid");
  if (!localGuid) {
    window.localStorage.setItem("guid", generateUUID());
  }
};

export const settingStorage = () => {
  const defaultObj = {
    onBoarding: true,
    proMotion: true,
    lang: /\/(en)/g.test(window.location.pathname) ? "en" : "th",
    topBar: true,
  };
  const settings = !!window.localStorage.getItem("settings");
  const showTopBar = (userData, checkKey) => {
    console.log("showProMotion ", userData, checkKey);
    return !!userData && !!userData.firstName && !!userData.lastName
      ? false
      : !checkKey.topBar
        ? false
        : true;
  };
  const showProMotion = (userData, checkKey) => {
    console.log("showProMotion ", userData, checkKey);
    return !!userData && userData.countJob > 0
      ? false
      : !checkKey.proMotion
        ? false
        : true;
  };
  const userData = getContact();

  if (!settings) {
    window.localStorage.setItem("settings", JSON.stringify(defaultObj));
  } else {
    const checkKey = JSON.parse(window.localStorage.getItem("settings"));
    checkKey.lang = /\/(en)/g.test(window.location.pathname) ? "en" : "th";

    if (Object.keys(checkKey).length !== 4) {
      defaultObj.proMotion = showProMotion(userData, checkKey);
      defaultObj.topBar = showTopBar(userData, checkKey);
      updateTobar({ topBar: defaultObj.topBar });
      console.log("checkKey ", defaultObj);
      window.localStorage.setItem("settings", JSON.stringify(defaultObj));
    } else {
      checkKey.proMotion = showProMotion(userData, checkKey);
      checkKey.topBar = showTopBar(userData, checkKey);
      updateTobar({ topBar: checkKey.topBar });
      console.log("checkKey ", checkKey);
      window.localStorage.setItem("settings", JSON.stringify(checkKey));
    }
  }
};

export const getStorageSettings = (key) => {
  const settings = window.localStorage.getItem("settings");
  if (!!settings) {
    const value = JSON.parse(settings)[key];
    if (!!value) {
      return value;
    }
    return false;
  }
  return false;
};

export const setStorageSettings = (obj) => {
  const setting = window.localStorage.getItem("settings");
  if (!!setting) {
    const preobj = JSON.parse(setting);
    if (!!preobj) {
      window.localStorage.setItem(
        "settings",
        JSON.stringify({ ...preobj, ...obj })
      );
    }
  }
};

export const setStorageObj = (name, obj) => {
  const storage = window.localStorage.getItem(name);
  if (!!storage) {
    const preobj = JSON.parse(storage);
    if (!!preobj) {
      window.localStorage.setItem(name, JSON.stringify({ ...preobj, ...obj }));
    } else {
      window.localStorage.setItem(name, JSON.stringify({ ...obj }));
    }
  } else {
    window.localStorage.setItem(name, JSON.stringify({ ...obj }));
  }
};

export const getStorage = (name) => {
  const obj = window.localStorage.getItem(name);
  if (!!obj) {
    const value = JSON.parse(obj);
    if (!!value) {
      return value;
    }
    return false;
  }
  return false;
};

export const initStorage = () => {
  settingStorage();
  const lang = getStorageSettings("lang");
  guid();
  setLang(lang);
  zE(() => {
    zE.setLocale(lang);
  });
  if (lang === "en") {
    if (!/\/(en)/g.test(window.location.pathname)) {
      let replaceUrl = window.location.pathname.replace(
        /(\/en\/|\/th\/|\/)/,
        "/en/"
      );
      if (!!window.location.search && !!window.location.hash) {
        replaceUrl = replaceUrl + window.location.search + window.location.hash;
      } else if (!!window.location.search) {
        replaceUrl = replaceUrl + window.location.search;
      } else if (!!window.location.hash) {
        replaceUrl = replaceUrl + window.location.hash;
      }
      window.history.replaceState(null, null, replaceUrl);
    }
  }
};
