import React from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import { compose, withState } from 'recompose';
import translate from './../../core/translate';
import s from './styles.scss';

const enhance = compose(withState('text', 'setText', false));

const Button = ({ ...props }) => (
  <div
    style={{
      background: 'transparent',
      zIndex: 999998,
      transform: 'translateZ(0px)',
      position: 'fixed',
      transition:
        'opacity 250ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, top, bottom',
      opacity: 1,
      width: '184px',
      height: '50px',
      maxHeight: '551px',
      minHeight: '50px',
      margin: '10px 20px',
      bottom: 16,
      right: 56,
    }}
  >
    <FloatingActionButton
      id="btnTextHelp"
      style={{
        float: 'right',
      }}
      iconStyle={{
        color: '#ffffff',
      }}
      onMouseEnter={() => {
        props.setText(true);
        document
          .getElementById('btnTextHelp')
          .style.setProperty('width', '184px', 'important');
        document
          .getElementById('btnTextHelp')
          .style.setProperty('border-radius', '999rem', 'important');
      }}
      onMouseLeave={() => {
        props.setText(false);
        document
          .getElementById('btnTextHelp')
          .style.setProperty('width', '56px', 'important');
      }}
      onClick={() => {
        zE(() => {
          zE.hide(); // eslint-disable-line no-undef
          zE.activate({ hideOnClose: true }); // eslint-disable-line no-undef
        });
      }}
    >
      <div style={{ display: 'flex' }}>
        <i
          style={{
            width: 56,
            lineHeight: '56px',
          }}
          className="material-icons"
        >
          headset_mic
        </i>
        <span className={s.needHelp}>
          {props.text && translate('callCenter.needHelp')}
        </span>
      </div>
    </FloatingActionButton>
  </div>
);

export default enhance(Button);
