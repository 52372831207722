const initScroll = {
  goto: false,
  ele: '',
};

export const scrollBar = (state = initScroll, action) => {
  switch (action.type) {
    case 'UPDATE_SPRINGSCROLL':
      return {
        ...state,
        ...action.state,
      };
    default:
      return {
        ...state,
      };
  }
};
