export const userAgent = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_USER_AGENT':
      return {
        ...action.state,
      };
    default:
      return state;
  }
};

const getDevice = (app) => {
  const supportMobileDevices = ['iPhone', 'iPad', 'iPod', 'Android'];
  const device = supportMobileDevices.filter((v) => app.includes(v));
  const isMobile = device && device.length > 0;
  return isMobile ? device.reduce((prev, curr) => curr) : 'n/a';
};

export const getAgentDeviceInfo = () => {
  const app = navigator.userAgent;
  const device = getDevice(app);
  const screen = window.screen;
  return {
    isMobile: device !== 'n/a',
    agent: app,
    device,
    width: screen.width,
    height: screen.height,
    orientation: screen.orientation ? screen.orientation.type : undefined,
  };
};

