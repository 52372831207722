import { RoomApi } from "../api/apiChat";
import store from "../core/store";

// #region ==== Action
export const ActionTypeUI = {
  updateChatUI: "CHAT_UPDATE_UI",
  resetChatUI: "CHAT_RESET_UI",
};
export const ActionType = {
  apiUpdateChatData: "CHAT_API_UPDATE_DATA",
  updateChatData: "CHAT_UPDATE_DATA",
  resetChatData: "CHAT_RESET_DATA",
};
// #endregion

// #region ==== Reducer
export const initStateUI = {
  open: false,
  room: null,
};

export const chatUI = (state = initStateUI, action = { type: null, state: {} }) => {
  switch (action.type) {
    case ActionTypeUI.updateChatUI:
      return {
        open: action.open,
        room: action.room,
      };
    case ActionType.resetChatUI:
      return initStateUI;
    default:
      return state;
  }
};

export const initState = {
  unreadRoomList: [],
  joinedRoomList: [],
};

export const chat = (state = initState, action = { type: null, state: {} }) => {
  switch (action.type) {
    case ActionType.updateChatData:
      return {
        unreadRoomList: action.unreadRoomList || state.unreadRoomList,
        joinedRoomList: action.joinedRoomList || state.joinedRoomList,
      };
    case ActionType.resetChatData:
      return initState;
    default:
      return state;
  }
};
// #endregion

// #region ==== Helpers & Dispatchers
export const getSumUnreadCount = ({ unreadRoomList = [], userId = "" }) => {
  const sumUnreadCount =
    unreadRoomList.length > 0
      ? unreadRoomList
          .map((r) => {
            const { members = [] } = r;
            const me = members.find(
              (m) => `${m.user}`.toLowerCase() === userId.toLowerCase()
            );
            if (me) {
              return me.unreadCount;
            }
            return 0;
          })
          .reduce((p, c) => p + c)
      : 0;
  return sumUnreadCount;
};

export const getSumUnreadCountJobRoom = ({
  unreadRoomList = [],
  userId = "",
}) => {
  const list = unreadRoomList.filter((r) => r._id !== userId);
  const sumUnreadCount =
    list.length > 0
      ? list
          .map((r) => {
            const { members = [] } = r;
            const me = members.find(
              (m) => `${m.user}`.toLowerCase() === userId.toLowerCase()
            );
            if (me) {
              return me.unreadCount;
            }
            return 0;
          })
          .reduce((p, c) => p + c)
      : 0;
  return sumUnreadCount;
};

export const getSumUnreadCountSupportRoom = ({
  unreadRoomList = [],
  userId = "",
}) => {
  const list = unreadRoomList.filter((r) => r._id === userId);
  const sumUnreadCount =
    list.length > 0
      ? list
          .filter((r) => r._id === userId)
          .map((r) => {
            const { members = [] } = r;
            const me = members.find(
              (m) => `${m.user}`.toLowerCase() === userId.toLowerCase()
            );
            if (me) {
              return me.unreadCount;
            }
            return 0;
          })
          .reduce((p, c) => p + c)
      : 0;
  return sumUnreadCount;
};

export const dispatchOpenChatBox = ({ open, room }) => {
  store.dispatch({
    type: ActionTypeUI.updateChatUI,
    open,
    room,
  });
};

export const dispatchUpdateChatData = ({
  open,
  room,
  unreadRoomList,
  joinedRoomList,
}) => {
  store.dispatch({
    type: ActionType.updateChatData,
    state: { open, room, unreadRoomList, joinedRoomList },
  });
};

export const dispatchApiUpdateChatData = async ({
  user,
  role = "user",
  onFinished,
}) => {
  try {
    const [a, b, c] = await Promise.all([
      RoomApi.listNeedAdminSupportRoom(),
      RoomApi.searchRoom({
        filter: {
          user,
          role,
          onlyUnread: true,
        },
        projection: {
          message: 0,
        },
      }),
      RoomApi.searchRoom({
        filter: {
          user,
          role,
        },
      }),
    ]);
    store.dispatch({
      type: ActionType.updateChatData,
      needAdminSupportRoomList: a.data || [],
      unreadRoomList: b.data || [],
      joinedRoomList: c.data || [],
    });
    if (onFinished) onFinished(true);
  } catch (error) {
    console.error(error);
    store.dispatch({
      type: ActionType.resetChatData,
    });
    if (onFinished) onFinished(true);
  }
};
// #endregion
