import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyBVMnvwF0GS6xqt8Ks9CjK8WLSV2vRM9rw',
  authDomain: 'skootar-project.firebaseapp.com',
  databaseURL: 'https://skootar-project.firebaseio.com',
  storageBucket: 'gs://skootar-project.appspot.com',
};

const sf = firebase.initializeApp(config);

export default sf;
